//--------------------------------------------------
// Dependencies
//--------------------------------------------------
import Vue from "vue"
import Vuex, {Store} from "vuex"
import VuexPersistence from "vuex-persist"

import spaces from "./SpacesStore"
import projects from "./ProjectsStore"
import datasets from "./DatasetsStore"
import testRuns from "./TestRunsStore"
import testRunConfigurations from "./TestRunConfigurationsStore.ts";
import testRunnerController from "./TestRunnerControllerStore"
import editor from "./EditorStore"
import workspace from "./WorkspaceStore" // this is the project workspace
import jobs from "./JobsStore"
import accountSettings from "./AccountSettingsStore"
import {RootState} from "@/store/types"
import pick from "lodash/pick"

//--------------------------------------------------
// Store
//--------------------------------------------------

Vue.use(Vuex)

export let store: Store<RootState>

const installed = false

export function install (options) {

	if (installed) {
		return store
	}

	const vuexPersist = new VuexPersistence({
		key:     "vuex", // our custom LocalStorageSync will save this to "uilc_<userID>_vuex"; EDIT (15 Feb 2021): we're not using LocalStorageSync anymore
		storage: options.storage,
		// the modules to persist
		reducer: (state: RootState) => {
			return {
				// accountSettings: state.accountSettings, // this is now synced to database
				editor:          pick(state.editor, ["activeTabs", "previousTabs"]),
			}
		},
	})

	// todo: we should add a watcher so that if local storage is updated (from another tab), the changes a propagated to vuex

	store = new Vuex.Store<RootState>({
		modules: {
			editor,
			spaces,
			projects,
			testRuns,
			testRunConfigurations,
			datasets,
			testRunnerController,
			workspace,
			jobs,
			accountSettings,
		},
		plugins: [vuexPersist.plugin],
	})

	return store

}
