/**
 * Get query parameters from URL
 * @param name
 * @param url
 */
export default function getQueryParameterByName(name: string, url?: string) {
    if (!url) {
        url = window.location.href
    }
    name = name.replace(/[\[\]]/g, "\\$&")
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url)
    if (!results) {
        return null
    }
    if (!results[2]) {
        return ""
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "))
}
