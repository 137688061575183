/* eslint-disable */

//
// Installation script
//

let gtag = function () {
}; // init to noop first

let installed = false;
let ENABLED   = true;

/**
 * Setup gtag
 * @param enabled If enabled, sets up `gtag`, otherwise initialises `gtag` fn to noop.
 */
export function install(enabled) {

	if (installed) {
		return;
	}

	ENABLED = enabled && __GOOGLE_ANALYTICS_TRACKING_ENABLED__;

	if (ENABLED) {

		try {

			// inject gtag.js
			let s   = document.createElement("script");
			s.async = 1;
			s.defer = 1;
			s.src   = "https://www.googletagmanager.com/gtag/js?id=" + __GOOGLE_ANALYTICS_TRACKING_ID__;
			let s0  = document.getElementsByTagName("script")[0];
			s0.parentNode.insertBefore(s, s0);

			// configure gtag.js
			window.dataLayer = window.dataLayer || [];
			gtag             = function () {
				window.dataLayer.push(arguments);
			};
			gtag("js", new Date());
			gtag("config", __GOOGLE_ANALYTICS_TRACKING_ID__, {
				// enable tracking across related domains as a single session
				linker: {
					domains: __GOOGLE_ANALYTICS_TRACKING_LINKED_DOMAINS__
				}
			});

			// todo: track signed in users.

		} catch (e) {
			console.error("Error configuring GA: ", e);
		}

	}
	installed = true;

}

/**
 * Track a user by ID (must not but personally identifiable info
 */
export function setUserId(userId) {
	try {
		if (userId) {
			gtag("config", __GOOGLE_ANALYTICS_TRACKING_ID__, {
				user_id: "userId"
			});
		}
	} catch (e) {

	}
}

export function trackPageView(path) {
	try {
		if (path && path !== "") {
			gtag("config", __GOOGLE_ANALYTICS_TRACKING_ID__, {
				page_path: path
			});
		}
	} catch (e) {

	}
}

/**
 * Track an event
 * Reference: https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @param {String} action  string that will appear as the event action in Google Analytics Event reports.
 * @param {String} [category] the string that will appear as the event category.
 * @param {String} [label] the string that will appear as the event label.
 * @param {Number} [value] non-negative integer that will appear as the event value.
 * @param {Boolean} [non_interaction] flag to indicate
 */
export function trackEvent(action, category = null, label = null, value = 0, non_interaction = false) {
	try {
		gtag("event", action, {
			event_category:  category,
			event_label:     label,
			value:           value,
			non_interaction: non_interaction
		});
	} catch (e) {
		console.error("Error tracking event: ", e);
		// do nothing
	}
}

export function trackSignupEvent() {
	trackEvent("sign_up", "conversion");
}

export function trackWriteTestEvent() {
	trackEvent("write_test", "engagement");
}

export function trackSaveTestEvent() {
	trackEvent("save_test", "engagement");
}

export function trackRunTestEvent() {
	trackEvent("run_test", "engagement");
}

export function trackShareLinkEvent() {
	trackEvent("share", "share");
}

export function trackShareEmbedCodeEvent() {
	trackEvent("share", "shareEmbed");
}

