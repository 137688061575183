import _Vue, {PluginFunction} from "vue"

export let eventHub: _Vue

const EventHubPlugin: PluginFunction<undefined> = (Vue: typeof _Vue) => {
	eventHub = new Vue({
		name: "EventHub",
	})

	Vue.prototype.$eventHub = eventHub

	Vue.mixin({
		created () {
			this.$eventHub = eventHub
		}
	})
}

export default EventHubPlugin
