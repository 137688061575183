import $api from "@uil/api"
import Vue from "vue"

/**
 * Run code to setup API
 */
export function run (app: any): Promise<void> {

	try {

		// if(typeof window.api === "undefined"){
		// 	let error = new Error("API is not loaded.")
		// 	window.STUDIO.printErrorOnScreen(error)
		// 	throw error
		// }
		// const $api = window.api

		// if baseurl is not false-y, override the api
		if (__UILICIOUS_API_BASEURL__) {
			// this value is injected by webpack during compilation
			$api._core.baseURL(__UILICIOUS_API_BASEURL__)
		}

		// Drop the admin endpoints
		delete $api.admin

		// expose $api to all vue instances
		Vue.mixin({
			beforeCreate () {
				this.$api = $api
			},
		})

		window.api = app.api = $api

		return Promise.resolve(app)

	} catch (e) {
		console.error("Error initialising UI-licious API client library: ", e)
	}

}