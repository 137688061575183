import Vue from "vue"

// Usage:
//v-allow:space-member-view.enable="{space:spaceID}"
//v-allow:[permission].disable="{space:spaceID, projectId: ''}"
//v-allow:[permission].hide="{space:spaceID}"

/**
 * Create a comment node
 * @param {*} el
 * @param {*} vnode
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
// function commentNode(el, vnode) {
// 	const comment = document.createComment("")

// 	Object.defineProperty(comment, "setAttribute", {
// 		value: () => undefined
// 	})

// 	// Update the Virtual DOM (vnode) so that future re-renders will always render an empty node (Vue uses a comment)
// 	vnode.text = ""
// 	vnode.elm = comment
// 	vnode.isComment = true
// 	vnode.context = undefined
// 	vnode.tag = undefined
// 	vnode.data.directives = undefined

// 	// Replace the element mounted to the vnode instance
// 	if (vnode.componentInstance) {
// 		vnode.componentInstance.$el = comment
// 	}

// 	// replace the element in the actual DOM
// 	if (el.parentNode) {
// 		el.parentNode.replaceChild(comment, el)
// 	}
// }

export default class VuePermissions {

	constructor() {
		this.state = new Vue({
			data: () => ({
				permissions: {}
			})
		})
		this.listeners = {}
	}

	/**
	 * Get the type of the entity
	 * e.g. Returns "space" given the that entity is `{"space": "7DtAWiDb7Ys8kHFJ3fr9qj"}`
	 * @param {*} entity
	 */
	_getEntityType(entity){
		try {
			if (typeof entity !== "object") {
				throw new TypeError("Expected `entity` to be an object")
			}
			if (Object.keys(entity).length !== 1) {
				throw new Error("Invalid permission `entity`, expected {entity_type: entity_id}")
			}
			return Object.keys(entity)[0]
		} catch (e) {
			throw new Error("Invalid permission `entity`, expected {entity_type: entity_id}")
		}
	}

	// _getEntityKey(entity) {
	// 	try {
	// 		if (typeof entity !== "object") {
	// 			throw new TypeError("Expected `entity` to be an object")
	// 		}
	// 		if (Object.keys(entity).length !== 1) {
	// 			throw new Error("Invalid permission `entity`, expected {entity_type: entity_id}")
	// 		}
	// 		let entityType = Object.keys(entity)[0]
	// 		return entityType + "_" + entity[entityType]
	// 	} catch (e) {
	// 		throw new Error("Invalid permission `entity`, expected {entity_type: entity_id}")
	// 	}
	// }

	/**
	 * Returns a curried function to set the given permissions on an entity
	 * @param {*} permissions
	 */
	setPermissions(permissions) {
		if (!Array.isArray(permissions)) {
			throw new TypeError("Expected `permissions` to be an Array")
		}
		return {
			on: (entity) => {
				if (typeof entity !== "object") {
					throw new TypeError("Expected `entity` to be an object")
				}
				let entityType = this._getEntityType(entity) // e.g. 'space', 'project'
				let entityID = entity[entityType].toString()
				this.state.permissions[entityType] = this.state.permissions[entityType] || {}
				this.state.permissions[entityType][entityID] = permissions

				// Call listeners
				let handlers = this.listeners.setPermissions
				if(handlers){
					handlers.forEach((handler)=>{
						handler({entityType, entityID, permissions})
					})
				}
			}
		}
	}

	/**
	 * Removes the permissions on an entity
	 * @param {*} entity
	 */
	removePermissionsOn(entity){
		this.setPermissions([]).on(entity)
	}

	/**
	 * Checks if the user is allowed to perform an action on an entity
	 * @param {*} permission
	 */
	isAllowed(permission) {
		if (typeof permission !== "string") {
			throw new TypeError("Expected `permission` to be a string")
		}
		if (permission === "") {
			throw new Error("`permission` cannot be an empty string")
		}
		return {
			on: (entity) => {
				if (typeof entity !== "object") {
					throw new TypeError("Expecter entity to be an object")
				}
				let entityType = this._getEntityType(entity)
				let entityID = entity[entityType].toString()
				if(!this.state.permissions[entityType]){
					return false
				}
				let permissions = this.state.permissions[entityType][entityID] || []
				return permissions.includes(permission)
			}
		}
	}

	/**
	 * Register listener
	 * @param {*} handler
	 */
	on(eventName, handler){
		this.listeners[eventName] = this.listeners[eventName] || []
		this.listeners[eventName].push(handler)
	}
}

function install(Vue, options) {
	//
	Vue.mixin({
		created() {
			// set permissions object on all components
			if (this.$options.permissions) {
				this.$permissions = this.$options.permissions
			} else {
				this.$permissions = this.$root.$permissions
			}

			// add alias to $permissions.isAllowed method to all components
			if (this.$permissions) {
				this.$isAllowed = this.$permissions.isAllowed.bind(this.$permissions)
			}
		}
	})

	//
	// Vue.directive("allow", {
	// 	bind: function(el, binding, vnode) {
	// 		// todo: look up the
	// 		console.log("v-allow", el, vnode)

	// 		// get permission from binding.arg
	// 		if (typeof binding.arg === "undefined" || binding.arg == null) {
	// 			console.warn("Missing permission for v-allow on element", el)
	// 		}
	// 		let permission = binding.arg

	// 		// get entity (the thing that is restricted on) from binding.value
	// 		if (typeof binding.value === "undefined" || binding.value == null) {
	// 			console.warn("Missing expression for v-allow on element", el)
	// 		}
	// 		if (typeof binding.value !== "object") {
	// 			console.warn("Invalid expression for v-allow on element, object expected", el)
	// 		}

	// 		let entity = binding.value

	// 		let entityTypes = Object.keys(entity)
	// 		if (entityTypes.length === 0) {
	// 			console.warn("Invalid expression for v-allow on element, object is empty", el)
	// 		}
	// 		let entityKey = entityTypes[0] + "_" + entity[entityTypes[0]]

	// 		// get permissions for the entity
	// 		let PERMISSIONS = {}
	// 		let entityPermissions = PERMISSIONS[entityKey] || []
	// 		let permitted = entityPermissions.includes(permission)

	// 		// by default, hide
	// 		let hide = true,
	// 			disable = false

	// 		// if disable, then don't hide
	// 		if (binding.modifiers.disable) {
	// 			hide = false
	// 			disable = true
	// 		}

	// 		// .hide overrides .disable
	// 		if (binding.modifiers.hide) {
	// 			hide = true
	// 			disable = false
	// 		}

	// 		// disable or enable
	// 		if (!permitted) {
	// 			// disable
	// 			if (disable) {
	// 				el.setAttribute("disabled", "") // disable the element
	// 			} /* hide */ else if (hide) {
	// 				if (vnode.componentInstance) {
	// 					vnode.componentInstance.$destroy()
	// 				}
	// 				el.parentNode.replaceChild(document.createComment(""), el)
	// 			}
	// 		}
	// 	}
	// })
}

VuePermissions.install = install

VuePermissions.sync = function (store, permissions){

	// create a "permission" module in the store
	store.registerModule("permissions", {
		namespaced: true,
		state:      {},
		mutations:  {
			setPermissions(state, {entityType, entityID, permissions}){
				state[entityType] = state[entityType] || {}
				state[entityType][entityID] = permissions
			},
			deletePermissions(state, {entityType, entityID}){
				state[entityType][entityID] = []
			}
		}
	})

	// Whenever permissions are set, commit it to the store
	permissions.on("setPermissions", function({entityType, entityID, permissions}){
		store.commit("permissions/setPermissions", {entityType, entityID, permissions})
	})

}
