import Vue from "vue"
import api from "@uil/api"
import Job from "@/models/Job"

interface State {
	// map of jobs by id
	jobsMap: any,
	// map of flags indicating projects which we have retrieved all jobs for at least once
	projectsIndexed: any
}

const state: State = {
	jobsMap:         {},
	projectsIndexed: {},
}

const mutations = {
	setJob (state: State, job: Job) {
		if (job) {

			// add or update job in the project group
			const jobID = job._oid
			if (!state.jobsMap[jobID]) {
				Vue.set(state.jobsMap, jobID, job)
			} else {
				// DON'T: state.jobsMap[jobID] = job
				// because we want to keep additional information on the existing job object
				// such as ".lastRun"
				// Recommended by vue: Use Object.assign to create a new object that merges the old
				// and the new data, in order to trigger reactivity
				let updatedJob = new Job(Object.assign({}, state.jobsMap[jobID], job))
				state.jobsMap[jobID] = updatedJob
			}

		}
	},
	removeJob (state: State, jobID: string) {
		Vue.delete(state.jobsMap, jobID)
	},
	setProjectIndexed (state: State, projectID: string) {
		Vue.set(state.projectsIndexed, projectID, true)
	},
}

const getters = {
	activeJob (state, getters, rootState) {
		const jobID = rootState.route.params.jobId
		if (jobID) {
			return state.jobsMap[jobID]
		}
		return null
	},
	jobsForActiveProject (state, getters, rootState) {
		const projectID = rootState.route.params.projectId
		if (projectID) {
			const allJobs = Object.values(state.jobsMap)
			return allJobs.filter((job: any) => {
				return job.projectID === projectID
			})
		}
		return []
	},
}

interface FetchJobParams {
	projectID: string;
	start?: number;
	length?: number;
	orderBy?: string;
}

const actions = {
	fetchJobs ({commit}, params: FetchJobParams) {
		return api.project.job.list(params).then((data) => {

			if (!data.result) {
				throw new Error("Missing `result` from response")
			}

			// add each job to the jobs map
			const jobs = []
			data.result.forEach((o) => {
				const job = new Job(o)
				commit("setJob", job)
				jobs.push(job)
			})
			data.result = jobs

			return data /* return the whole data object, because we want the count */

		})
	},
	fetchJob ({commit}, jobID: string): Promise<Job> {
		return api.project.job.get(jobID).then((data) => {

			if (!data.result) {
				throw new Error("Missing `result` from response")
			}

			const job = new Job(data.result)
			commit("setJob", job)

			return job

		})
	},
	addJob ({commit, dispatch}, params: any): Promise<Job> {
		const projectID = params.projectID || params.projectId || ""
		return api.project.job.add(projectID, params).then((data) => {

			if (!data.result) {
				throw new Error("Missing `result` from response")
			}

			const jobID = data.result
			return dispatch("fetchJob", jobID)
		})
	},
	updateJob ({commit, dispatch}, params: any): Promise<Job> {
		const projectID = params.projectID || params.projectId || ""
		const jobID = params._oid || params.jobID
		return api.project.job.update(projectID, jobID, params).then((data) => {
			let deferred
			if (data.result) { // we should get the updated job in the result
				const job = new Job(data.result)
				commit("setJob", job)
				deferred = Promise.resolve(job)
			} else {
				deferred = dispatch("fetchJob", jobID)
			}
			return deferred
		})
	},
	deleteJob ({commit}, jobID: string) {
		return api.project.job.delete(jobID).then(() => {
			// delete the job after some time, so that the UI has time to navigate away from job detail page
			setTimeout(() => {
				commit("removeJob", jobID)
			}, 500)
		})
	},
	disableJob ({commit, dispatch}, jobID: string) {
		return api.project.job.disable(jobID).then((data) => {
			return dispatch("fetchJob", jobID)
		})
	},
	enableJob ({commit, dispatch}, jobID: string) {
		return api.project.job.enable(jobID).then((data) => {
			return dispatch("fetchJob", jobID)
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
