import _ from "lodash"
import moment from "moment"
import {CakeModels, formatDuration} from "@uilicious/cake-ui"
import TestRunStep from "./TestRunStep"
import {
	ITestRun,
	Box,
	TestRunData,
	TestRunRecord,
	TestRunResult,
	TestRunStepRecord,
	TestRunType, TestRunDownloadedFile,
} from "@uil/models/types"
import {STATUS, TestRunStatus, TestRunStatusMap} from "@uil/models/TestRunStatus"
import Vue from "vue"
import {Browser} from "@uil/definitions/browsers"

/**
 * @class TestRun
 */
export default class TestRun implements ITestRun {
	public static STATUS: TestRunStatusMap

	public projectName: string
	public _oid: string
	public reason: string
	public error: string
	public type: TestRunType
	public abandoned: boolean
	public running: boolean
	public stopping: boolean
	public integrations
	public environmentDataID
	public createdBy: string

	// Private variables
	private _id: string
	private _projectID: string
	private _jobID: string
	private _testRunSetID: string
	private _filePath: string
	private _name: string
	private _browser: string
	private _resolution: Box
	private _latestImg: string
	private _region: string
	private _data: TestRunData
	private _result: TestRunResult
	private _status: TestRunStatus
	private _outputPath: string
	private _runTime: number
	private _workspacePath: string
	private _steps: TestRunStep[]
	private _hasFailedIssues: boolean
	private _issueCounts: ITestRun["issueCounts"]
	private _downloadedFiles: TestRunDownloadedFile[]

	/**
	 * @deprecated
	 */
	public environment

	/**
	 * @deprecated
	 */
	public environmentDataId
	/**
	 * @deprecated
	 */
	private _createTimestamp: number

	/**
	 * @deprecated
	 */
	public runTime_ms: number

	/**
	 * @deprecated
	 */
	public _createdTime?: number

	/**
	 * @deprecated
	 */
	public _createTime?: number

	/**
	 *
	 * @param o
	 *
	 */
	constructor (o: TestRunRecord) {
		if (o == null) {
			throw new Error("Cannot initialise TestRun object with null")
		}

		// set all values
		Object.keys(o).forEach((k) => {
			try {
				this[k] = o[k]
			} catch (e) {
				console.warn(e)
			}
		})

		// ids
		this.id = o._oid || o.ID  /*deprecated*/ || o.id /*deprecated*/

		// test name / path
		this.filePath = o.filePath || ""
		this.name = o.name || ""

		// project info
		this.projectID = o.projectID
		this.projectName = o.projectName

		// job info
		this.jobID = o.jobID

		// test run set info
		this.testRunSetID = o.testRunSetID

		// run configuration
		this.browser = o.browser
		this.resolution = o.resolution
		this.region = o.region
		this.data = o.data || {}
		this.result = o.result
		this.environmentDataID = o.environmentDataID ?? o.environmentDataId ?? o.environment
		this.createdBy = o.createdBy

		// run info
		this.runTime = o.runTime_ms /* missing in api v3 for some reason */ || (o.runTime ? (o.runTime * 1000) : null)
		this.workspacePath = o.workspacePath
		this.outputPath = o.outputPath

		// results
		this.abandoned = typeof o.abandoned === "undefined" ? false : !!o.abandoned
		this.status = o.status || STATUS.INIT
		this.latestImg = o.latestImg
		this.reason = o.reason || o.error || o.description
		this.running = false // for running test dropdown drawer
		this.stopping = false // for stop button
		this.hasFailedIssues = o.hasFailedIssues ?? false
		this._issueCounts = o.issueCounts ?? {
			total:  0,
			failed: 0,
		}

		// results - steps
		this.steps = []
		if (_.isArray(o.steps)) {
			_.forEach(o.steps, (step, i) => {
				this.setStep(i, step)
			})
		}

		this._downloadedFiles = o.result?.downloadedFiles ?? []
	}

	//--------------------------------------------------
	// Accessors
	//--------------------------------------------------

	//------------------------------------
	// Accessors
	// - IDs
	//------------------------------------

	get id () {
		return this._id
	}

	set id (value) {
		this._id = value
	}

	get projectID () {
		return this._projectID
	}

	set projectID (value) {
		this._projectID = value
	}

	get jobID () {
		return this._jobID
	}

	set jobID (value) {
		this._jobID = value
	}

	get testRunSetID () {
		return this._testRunSetID
	}

	set testRunSetID (value) {
		this._testRunSetID = value
	}

	get result(): TestRunResult {
		return this._result
	}

	set result (value) {
		this._result = value
	}

	//------------------------------------
	// Accessors
	// - Test run settings
	//------------------------------------

	get filePath () {
		return this._filePath
	}

	set filePath (value) {
		this._filePath = value
	}

	get name () {
		if (!_.isNil(this._name) && this._name !== "") {
			return this._name
		}

		// get name of test from file path
		let name = this._filePath
		if (name.endsWith(".test.js")) {
			name = name.substring(0, name.length - 8)
			this._name = name
		}
		return this._name
	}

	set name (value) {
		this._name = value
	}

	get browser () {
		return this._browser
	}

	set browser (value) {
		this._browser = value
	}

	get browserVersion () {
		return this.result?.session.browserVersion ?? ""
	}

	get browserName () {
		const browser = Browser[this._browser]

		return browser?.name
	}

	get browserDetails(): string {
		return `${this.browserName} ${this.browserVersion}`
	}

	get resolution () {
		return this._resolution
	}

	set resolution (value) {
		this._resolution = new CakeModels.Rectangle(value)
	}

	get resolutionFormatted () {
		if (this.resolution == null) {
			return "-"
		}
		return this.resolution.toString().replace("x", " x ")
	}

	get region () {
		return this._region
	}

	set region (value) {
		this._region = value
	}

	get data () {
		return this._data
	}

	set data (value) {
		this._data = value
	}

	//------------------------------------
	// Accessors
	// - Test run info
	//------------------------------------

	get workspacePath () {
		return this._workspacePath
	}

	set workspacePath (value) {
		this._workspacePath = value
	}

	get outputPath () {
		return this._outputPath
	}

	set outputPath (value) {
		this._outputPath = value
	}

	/**
	 * When the test run was started
	 */
	get runTime () {

		return this._runTime /* this should be normalised by setter to milliseconds */
			|| this.runTime_ms
			// fallback to the creation timestamp (spelt in various ways),
			|| this._createTimestamp || this._createdTime || this._createTime
			// fallback to the timestamp of the first step...
			|| (this.steps[0] && this.steps[0].timestamp)

	}

	/**
	 * Set timestamp when the test run is created
	 */
	set runTime (value /* this should be in milliseconds */) {
		if (typeof value === "undefined" || value == null) {
			// ignore
		} else if (typeof value !== "number") {
			console.error("Invalid runTime - ", value)
		} else if (value >
			9999999999 /* if the value is more than 10 digits, then it is milliseconds, or it's seconds but the year is 2286 */) {
			this._runTime = value
		} else {
			this._runTime = value * 1000 // convert seconds to milliseconds
		}
	}

	/**
	 * @deprecated Use VueJS filters instead for formatting
	 */
	get runTime_formatted () {
		if (_.isNil(this.runTime)) {
			return "-"
		}
		return moment(this.runTime).format("DD MMM YYYY, hh:mm A")
	}

	//------------------------------------
	// Accessors
	// - Status
	//------------------------------------

	get status () {
		return this._status
	}

	set status (value) {
		this._status = value
	}

	get isTerminated () {
		return this._status === STATUS.TERMINATED
	}

	get isInitialising () {
		return this._status === STATUS.INIT
	}

	get isPending () {
		return this._status === STATUS.PENDING
	}

	get isSuccess () {
		return this._status === STATUS.SUCCESS && !this._hasFailedIssues
	}

	get isFailure () {
		return this._status === STATUS.FAILURE || this._hasFailedIssues
	}

	get isError () {
		return this._status === STATUS.ERROR || this._status === STATUS.SYSTEM_ERROR
	}

	get isMaxRequestErr () {
		return this._status === STATUS.MAX_REQUEST_ERROR
	}

	get isNoServerAvailableErr () {
		return this._status === STATUS.NO_SERVERS_AVAILABLE
	}

	get isSystemError () {
		return this._status === STATUS.SYSTEM_ERROR
	}

	//------------------------------------
	// Accessors
	// - Screenshots
	//------------------------------------

	get latestImg () {
		return this._latestImg
	}

	set latestImg (value) {
		this._latestImg = value
	}

	// This will handle screenshot unavailable between steps
	get screenshots () {

		const screenshots = []
		let lastAvailableScreenshot = null

		this.steps.forEach((step, i) => {
			let screenshot = null
			if (step.isScreenshotUnavailable) {
				screenshot = lastAvailableScreenshot
			} else {
				screenshot = step.screenshot // this will load the before or after action screenshot
				lastAvailableScreenshot = step.screenshot
			}
			screenshots.push(screenshot)
		})
		return screenshots

	}

	//------------------------------------
	// Accessors
	// - Test run steps
	//------------------------------------

	get steps () {
		return this._steps
	}

	set steps (value) {
		if (this._steps === undefined) {
			this._steps = [] // ensure steps is initialised to an empty array
		}

		if (value != null && value.length > 0) {
			_.forEach(value, (step, i) => {
				this.setStep(i, step)
			})
		}
	}

	get numberOfSteps () {
		return this.steps.length
	}

	get lastStepIndex () {
		return this.steps.length - 1
	}

	get lastExecutedStepIndex () {
		let lastExecutedStepIndex = -1
		_.forEach(this.steps, (step) => {
			if (step.status === TestRunStep.STATUS.SUCCESS || step.status === TestRunStep.STATUS.FAILURE) {
				lastExecutedStepIndex = step.index
			} else {
				return false
			}
		})
		return lastExecutedStepIndex
	}

	get lastExecutedStep () {
		return this.getStep(this.lastExecutedStepIndex)
	}

	//------------------------------------
	// Accessors
	// - Overall report
	//------------------------------------

	get errorCount () {
		let count = 0
		_.forEach(this.steps, (step) => {
			if (!step.isFetch && step.isFailure) {
				count = count + 1
			}
		})
		return count
	}

	/**
	 * Get the raw time taken (step execution + pre and post processing time) of the test in seconds
	 * @readonly
	 * @memberof TestRun
	 */
	get totalTimeTaken () {
		if (this.lastExecutedStep !== null) {
			// todo: we should probably use time when session start instead of runTime (which is the creation timestamp on the api side)
			const start = this.runTime  // in milliseconds
			const end = this.lastExecutedStep.timestamp
			return (end - start) / 1000
		}
		return 0

	}

	/**
	 * Get sum of the time taken to execute each step in seconds
	 * @return {number}
	 */
	get stepTimeTaken () {
		let time = 0
		_.forEach(this.steps, (step) => {
			try {
				if (!step.isPending) {
					time += step.timeTaken
				} else {
					return false
				}
			} catch (e) {
				return false
			}
		})
		return time
	}

	get formattedTimeTaken () {
		if (!this.isPending) {
			try {
				return formatDuration(this.totalTimeTaken, 1)
			} catch (e) {
				// do nothing
			}
		}
		return ""
	}

	get hasFailedIssues (): boolean {
		return this._hasFailedIssues
	}

	set hasFailedIssues (value: boolean) {
		this._hasFailedIssues = value
	}

	get issueCounts (): ITestRun["issueCounts"] {
		return this._issueCounts
	}

	set issueCounts (value: ITestRun["issueCounts"]) {
		this._issueCounts = value
	}

	get downloadedFiles (): TestRunDownloadedFile[] {
		return this._downloadedFiles
	}

	set downloadedFiles (value: TestRunDownloadedFile[]) {
		this._downloadedFiles = value
	}

	//--------------------------------------------------
	// Instance methods
	//--------------------------------------------------

	/**
	 * Get the step at a given index
	 * @param {Number} index Index of the step
	 * @return {TestRunStep} Step
	 */
	getStep (index) {
		if (index >= 0 && index < this.steps.length) {
			return this.steps[index]
		}
		return null
	}

	getStepByStepNum(stepNum){
		if (stepNum >= 1 && stepNum <= this.steps.length) {
			return this.steps[stepNum - 1]
		}
		return null
	}

	/**
	 * Set the step at a given index
	 * @param {Number} index Index of the step
	 * @param {Object} stepData Step data
	 */
	setStep (index: number, stepData: TestRunStepRecord) {
		const step = this.getStep(index)
		if (step == null) {
			// add step
			this._steps.splice(index, 1, new TestRunStep(this, index, stepData)) // use splice so that Vue will detect the change
		} else {
			// update step
			step.update(stepData)
		}
	}

	getScreenshot (index) {
		if (index >= 0 && index < this.steps.length) {
			return this.screenshots[index]
		}
		return null
	}

	/**
	 * Update test run
	 */
	update (o = {}) {
		if (typeof o !== "object" || o == null) {
			console.error(`Error updating TestRun ${this._oid} - unexpected update object - `, o)
		} else {
			Object.keys(o).forEach((k) => {
				const value = o[k]

				if (k === "_steps" && value?.length === 0) {
					return
				}

				if (k === "reason") {
					this.reason = value
					this.error = value
				} else {
					Vue.set(this, k, value)
				}
				// update each step
			})
		}

	}
}

TestRun.STATUS = STATUS
