import moment from "moment"

let installed = false

function install(Vue){

	if(installed){
		return
	}

	Vue.filter("datetime", function (value /* expect epoch milliseconds or Date */, format /* expect string or object*/) {
		if(typeof value === "number"){
			value = new Date(value)
		}
		if(value instanceof Date){
			if(typeof format === "string" && format !== null && format !== ""){
				let time = moment(value)
				return time.format(format)
			}
			let options = {
				weekday:   "short",
				day:       "numeric", // "1" instead of "01"
				month:     "short", // "Oct"
				year:      "numeric", // "2019" instead of "19"
				hour:      "2-digit",
				hourCycle: "h12",
				minute:    "2-digit"
			}
			if(typeof format === "object" && format !== null){
				options = format
			}
			return value.toLocaleString(undefined, options)
		}
		return value
	})

	installed = true

}

export default {
	install
}