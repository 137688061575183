var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "cake-button",
    {
      staticClass: "brand upgrade-plan-tag",
      attrs: { size: _vm.size },
      on: { pointerdown: _vm.onClick },
    },
    [
      _c("i", { staticClass: "fa fa-flash" }),
      _vm._v(" "),
      _vm._t("default", function () {
        return [_vm._v("Upgrade your plan")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }