/**
 * Enable developer preview mode
 * This will show features that will be released to production, but is experimental
 */
export function enableDeveloperPreview() {
    document.cookie = "devPreview=1;max-age=214748364" // never expires (almost)
    location.reload()
}

/**
 * Disable developer preview mode
 * This will hide features that will be released to production, but is experimental
 */
export function disableDeveloperPreview() {
    document.cookie = "devPreview=0;max-age=2147483647" // never expires (almost)
    location.reload()
}

export function isDeveloperPreviewEnabled() {
    let devPreview = document.cookie.replace(/(?:(?:^|.*;\s*)devPreview\s*=\s*([^;]*).*$)|^.*$/, "$1")
    return devPreview === "1"
}
