/* eslint-disable */

const INTERCOM_SETTINGS = {
	app_id: __INTERCOM_APP_ID__
};

let installed = false;
let ENABLED = true;
let HIDDEN = false

/**
 * Setup intercom
 * @param enabled If enabled, sets up Intercom, otherwise initialises Intercom global var to noop.
 */
export function install(enabled) {

	if (installed) {
		return;
	}

	ENABLED = enabled && __INTERCOM_ENABLED__;

	if (ENABLED) {

		(function () {

			var w  = window;
			var ic = w.Intercom;
			if (typeof ic === "function") {
				ic("reattach_activator");
				ic("update", INTERCOM_SETTINGS);
			} else {
				var d      = document;
				var i      = function () {
					i.c(arguments);
				};
				i.q        = [];
				i.c        = function (args) {
					i.q.push(args);
				};
				w.Intercom = i;

				var s   = d.createElement("script");
				s.type  = "text/javascript";
				s.async = true;
				s.defer = true;
				s.src   = "https://widget.intercom.io/widget/" + __INTERCOM_APP_ID__;
				var x   = d.getElementsByTagName("script")[0];
				x.parentNode.insertBefore(s, x);
			}

			// Append style to hide / show intercom
			var css = document.createElement('style');
			css.type = 'text/css';
			css.appendChild(document.createTextNode(".hide-intercom #intercom-container { display: none; }"))
			document.getElementsByTagName("head")[0].appendChild(css)

			// Hide / show based on saved config in the cookie
			isHiddenByDefault() ? hide({remember: true}) : show({remember: true})

			// If there unread count > 0, force intercom to show if hideen
			// Intercom('onUnreadCountChange', function(unreadCount) {
			// 	if(unreadCount > 0 && isHidden()){
			// 		show()
			// 	}
			// });

		})();
	} else {
		//initialise to a noop
		window.Intercom = function () {
		};
	}

	installed = true;

}

/**
 * Return intercom enabled flag
 */
export function isEnabled(){
	return ENABLED
}

//--------------------------------------------------
// API
// Refer to https://installing-intercom.readme.io/v1.0/docs/intercom-javascript
//--------------------------------------------------

/**
 * Start up intercom and log the user in
 * @param user
 */
let loggedIn = false;

export function login(user) {

	if (!user) {
		return;
	}

	if (loggedIn) {
		return;
	}

	if (ENABLED) {

		// perform login
		window.Intercom("boot", { // user data
			app_id:  __INTERCOM_APP_ID__,
			user_id: user._oid,
			email:   user.email,
			name:    user.name
		});
	}

	loggedIn = true;
}

/**
 * Track impression and update user info
 * This will also try to get new messages for the user
 */
export function updatePageChange(aboutUser = {}) {
	ENABLED && window.Intercom("update", aboutUser);
}

/**
 * Track event
 * @param event {String} Name of the event
 * @param metadata {{*}} Metadata object about the event
 */
export function trackEvent(event, metadata = undefined) {
	try {
		ENABLED && window.Intercom("trackEvent", event, metadata);
	} catch(e) {
		// do nothing
	}
}

/**
 * Log the user out
 * @param user
 */
export function logout(user) {
	// we need to properly terminate the session for a user
	// so that if they are using a shared computer, they should not be able to see the previous user's conversation
	ENABLED && window.Intercom("shutdown");
}

/**
 * Hide the intercom app
 * @param {*} remember
 */
export function hide({remember}){
	try {
		if(ENABLED){
			if(remember){
				document.cookie = "intercom_display=0;max-age=214748364" // never expires (almost)
			}
			let bodyCls = document.body.getAttribute("class") || ""
			document.body.setAttribute("class", bodyCls + " hide-intercom")
			HIDDEN = true
		}
	} catch(e) {
		console.error("Error hiding intercom", e)
	}
}

/**
 * Hide the intercom app
 * @param {*} remember
 */
export function show({remember}){
	try {
		if(ENABLED){
			if(remember){
				document.cookie = "intercom_display=1;max-age=214748364" // never expires (almost)
			}
			let bodyCls = document.body.getAttribute("class") || ""
			document.body.setAttribute("class", bodyCls.replace("hide-intercom", ""))
			HIDDEN = false
		}
	} catch(e) {
		console.error("Error showing intercom", e)
	}
}

/**
 * Check if the intercom app is configured to be hidden
 */
export function isHidden() {
	return HIDDEN
}

export function isHiddenByDefault() {
	return document.cookie.indexOf("intercom_display=0") !== -1
}


export function toggleChatWidget(hidden) {
	if (!ENABLED) {
		return;
	}

	window.Intercom('update', {
		"hide_default_launcher": hidden
	});
}

/**
 * Close messages
 */
export function close() {
	ENABLED && window.Intercom("hide");
}

/**
 * Open messages
 */
export function open({showNewMessage} = {}) {
	if(ENABLED){
		if(!showNewMessage){
			window.Intercom("show")
		} else {
			if(typeof showNewMessage === "boolean"){
				window.Intercom('showNewMessage')
			}else{
				window.Intercom('showNewMessage', showNewMessage.toString())
			}
		}
	}
}

/**
 * Show messages
 */
export function showMessages() {
	ENABLED && window.Intercom("showMessages");
}

/**
 * Show new messages
 */
export function showNewMessages() {
	ENABLED && window.Intercom("showNewMessages");
}
