import Vue from "vue";
import type {TourConfig} from "@uilicious/cake-ui/src/plugins/Tour/src/types"
import {TourButtons} from "@uilicious/cake-ui"
import event from "@/event"
import router, { ROUTE } from '@/router';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const welcomeImage = require("@/assets/img/product-tour/onboarding-splash.png")
const {nextButton, skipButton, endTourButton} = TourButtons

const config: TourConfig = {
	name:  "gettingStarted",
	steps: [
		// welcome
		{
			content: `
			<h4>Welcome to UI-licious!</h4>
			<img class="img-fluid mb-4" src="${welcomeImage}" alt="Welcome to UI-licious" />
			<div>
				<p>We're delighted have you on-board! UI-licious is a powerful tool for automating tests for your web applications across browsers. </p>
				<p>Let us show you how to get started.</p>
			</div>`,
			centered: true,
			buttons:  [
				nextButton("Start tour"),
			]
		},
		// write a test (skipped if the modal is not opened)
		{
			title:   "Create Your First Test",
			content: `<p>Give your test a meaningful name like "Login to MyApp" and provide the url of the website to test.

<p class="text-primary font-weight-bold"><i class="fa fa-arrow-right"></i> Click on the "Add" button to proceed</p>`,
			attachTo: "[data-ref=modal-window]",
			hotspot:  {
				attachTo:  "[data-ref=modal-confirm-button]",
				placement: "bottom",
			},
			placement: "bottom",
			on:        {
				[event.TEST.CREATED]: tour => tour.nextStep(),
			},
			beforeSetup: (api, currentStep) => {
				const modalEl = document.querySelector(currentStep.attachTo)

				if (modalEl) {
					// continue as usual
					return true
				}

				// modal not open, skip this step
				api.nextStep(2)
				return false
			},
		},
		// code editor
		{
			title:   "Write a Test",
			content: `<p>
This is the editor where you can write your tests.<br/>
Here's a template to help you get started!
</p>`,
			attachTo:  "[data-ref=code-editor-pane]",
			placement: "right",
			buttons:   [
				nextButton(),
				skipButton(),
			]
		},
		// test run toolbar
		{
			title:   "Run a Test",
			content: `<p>In this toolbar, you can select the browser and resolution to run your test.</p><p>For now, let's proceed with the defaults and run the test.</p>
<p class="text-primary font-weight-bold"><i class="fa fa-arrow-right"></i> Click the "Run" button</p>`,
			attachTo: "[data-ref=run-test-toolbar]",
			// attachTo: "[data-ref=btn-run-test]",
			hotspot:  {
				attachTo:  "[data-ref=btn-run-test]",
				placement: "bottom",
			},
			placement: "bottom",
			on:        {
				[event.TEST_RUN.INIT]: tour => tour.nextStep(),
			},
		},
		// test run report
		{
			title:     "View Test Run",
			content:   `<p>Once the test starts running, you will see the test result here. Screenshots are automatically taken for each step.</p>
<p>Click on a step to view its screenshot.</p>`,
			attachTo:  "[data-ref=test-preview-pane]",
			hotspot:  {
				// attachTo: ".report-step-list",
				attachTo:  ".report-step:nth-last-of-type(3)", // the third last report-step
				placement: "bottom",
			},
			placement: "left",
			buttons:   [
				nextButton(),
				skipButton(),
			]
		},
		// the directory pane
		{
			title:     "Your Files",
			content:   `<p>Your project files will be shown here. Click <i class="fa fa-flask"></i> to create a new test, or click <i class="fa fa-plus"></i> to add a new file.</p>`,
			attachTo:  "[data-ref=directory-pane]",
			hotspot:  {
				attachTo:  "[data-ref=add-test-btn]",
				placement: "bottom",
			},
			placement: "right",
			buttons:   [
				nextButton(),
				skipButton(),
			],
			on:        {
				[event.DIRECTORY.MODAL_OPENED] : tour => tour.nextStep()
			},
		},
		// new test file
		{
			title:     "Create a Test",
			content:   `Create a new test. Give it a meaningful name like "Login to MyApp"`,
			attachTo: "[data-ref=modal-window]",
			hotspot:  {
				attachTo:  "[data-ref=modal-confirm-button]",
				placement: "bottom",
			},
			placement: "right",
			buttons:   [
				nextButton(),
				skipButton(),
			],
			beforeSetup: (api, currentStep) => {
				const modalEl = document.querySelector(currentStep.attachTo)

				if (modalEl) {
					// continue as usual
					return true
				}

				// modal not open, skip this step
				api.nextStep(2)
				return false
			},
			on:        {
				[event.DIRECTORY.MODAL_CLOSED] : tour => tour.nextStep()
			}
		},
		// the learn tab
		{
			title:     "Learn more",
			content:   "<p>🙌 Congrats on writing and running your first test!</p><p> Find out more about testing with UIlicious in the \"Learn\" tab.</p>",
			attachTo:  "[data-ref=tab-learn]",
			hotspot:  {
				attachTo:  "[data-ref=tab-learn]",
				placement: "right",
			},
			placement: "right",
			buttons:   [
				endTourButton(),
			],
			on: {
				[event.TUTORIAL.IS_OPENED] : (tour) => {
					tour.end(false /* don't show prompt to confirm ending tour */)
				}
			}
		},
	],
}

export default config