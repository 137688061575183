import {colorHash} from "@uilicious/cake-ui"
import api from "@uil/api"

class Project {
	/**
	 *
	 * @param _oid
	 * @param id Deprecated
	 * @param name
	 * @param title Deprecated
	 */
	constructor(o) {

		this._oid = null
		this._name = ""
		this._spaceID = "" /* Leave as "" to indicate that it belongs to the "legacy" space */
		this._space = null
		this._ownerID = null
		this._userRole = null /* Role of the currently logged in user */
		this._features = {} /* features of the space that this project belongs to */
		this._backgroundColor = ""
		this._logoSize = 1
		this._logoURL = null

		if (typeof o === "object") {
			Object.assign(this, o)
		}
	}

	/**
	 * Get the project id
	 */
	get _oid() {
		return this.__oid
	}

	/**
	 * Set the project id
	 */
	set _oid(value) {
		this.__oid = value ? value.toString() : null // Coerces the id to a string
	}

	/**
	 * Get the project ID
	 * @deprecated
	 */
	get id() {
		return this._oid
	}

	/**
	 * Set the id of the project. Coerces the id to a string
	 * @param value
	 * @deprecated
	 */
	set id(value) {
		this._oid = value
	}

	/**
	 * Get the name of the project
	 * @return {string} The name of the project
	 */
	get name() {
		return this._name
	}

	/**
	 * Set the name of the project
	 * @param {string} name - Project name
	 */
	set name(name) {
		this._name = name
	}

	/**
	 * Get the name of the project
	 * @return {string} The name of the project
	 * @deprecated
	 */
	get title() {
		return this.name
	}

	/**
	 * Set the name of the project
	 * @param {string} title - Project name
	 * @deprecated
	 */
	set title(value) {
		this.name = value
	}

	/**
	 * @summary Get display title for the project
	 * @description Get display title of the project. If the title is null, returns "Untitled Project"
	 * @return {*}
	 */
	get displayTitle() {
		if (_.isNil(this.title) || this.title === "") {
			return "Untitled"
		}
		return this.title
	}

	get color() {
		if (this._backgroundColor != null) {
			return this.backgroundColor
		}

		return colorHash(this._oid)
	}

	get space() {
		return this._space
	}

	set space(space) {
		this._space = space
	}

	get spaceID() {
		if (this.space) {
			return this.space._oid
		}
		return this._spaceID ? this._spaceID : "" // coerce falsey values to empty string as we want to use the spaceID as a key
	}

	set spaceID(spaceID) {
		if (this.space) {
			console.warn("Cannot set `spaceID` on project because `space` is set")
			return
		}
		this._spaceID = spaceID ? spaceID.toString() : ""
	}

	get ownerID() {
		return this._ownerID
	}

	set ownerID(ownerID) {
		this._ownerID = ownerID
	}

	get userRole() {
		if (this.space) {
			return this.space.userRole
		}
		return this._userRole
	}

	set userRole(userRole) {
		if (this.space) {
			console.warn("Cannot set `userRole` on project because `space` is set")
			return
		}
		this._userRole = userRole
	}

	get subscription() {
		return this.space ? this.space.subscription : null
	}

	get plan() {
		return this.subscription ? this.subscription.plan : null
	}

	get features() {
		if (this.space) {
			return this.space.features
		}
		return this._features
	}

	setFeatures(features) {
		console.warn("Project.setFeatures() is deprecated")
		this._features = features
	}

	get logoSize() {
		return this._logoSize
	}

	set logoSize(logoSize) {
		this._logoSize = logoSize
	}

	get backgroundColor() {
		return this._backgroundColor
	}

	set backgroundColor(backgroundColor) {
		this._backgroundColor = backgroundColor
	}

	get logoURL() {
		return this._logoURL != null ? api._core.baseURL() + this._logoURL : undefined
	}

	set logoURL(value) {
		this._logoURL = value
	}
}

export default Project
