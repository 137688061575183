var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    attrs: { title: _vm.title, size: "small" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", [
              _vm.message
                ? _c("p", [
                    _vm._v("\n\t\t\t\t" + _vm._s(_vm.message) + "\n\t\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\tChoose a plan to activate your account and continue using UI-licious to:\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [_vm._v("Create projects and tests")]),
                _vm._v(" "),
                _c("li", [_vm._v("Run tests")]),
                _vm._v(" "),
                _c("li", [_vm._v("Schedule and run jobs")]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("cake-button", { on: { click: _vm.close } }, [
                  _vm._v("\n\t\t\t\tClose\n\t\t\t"),
                ]),
                _vm._v(" "),
                _c(
                  "upgrade-tag",
                  { attrs: { space: _vm.activeSpace, size: "md" } },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }