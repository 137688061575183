import {default as TestRun} from "@uil/models/TestRun"
import api from "@uil/api"
import * as _ from "lodash"
import Vue from "vue"
import {STATUS as TEST_RUN_STATUS, TestRunStatus} from "@uil/models/TestRunStatus"
import {GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store/types"
import {test} from "linkifyjs"

const CACHE_LIMIT = 25 // store up to n reports in memory

export interface ConcurrencySet {
	browsers: string[];
	total: number;
	running: number;
	testRunIDs: string[];
}

export interface State {
	isFetching: boolean;
	list: string[];
	map: Record<string, TestRun>;
	concurrencySets: ConcurrencySet[];
	accessTimestamp: Record<string, number>;
}

const state: State = {
	isFetching:      false,
	// The state is a map of test runs by IDs
	list:            [],
	map:             {},
	concurrencySets: [],
	// store access timestamp (so that we can limit how many objects we keep in the cache)
	accessTimestamp: {},
}

const mutations: MutationTree<State> = {
	setIsFetching (state, isFetching: boolean) {
		state.isFetching = isFetching
	},
	setList (state, testRunIDs: string[]) {
		state.list = testRunIDs
	},
	/**
	 * Set a test run in the store
	 * @param state
	 * @param o
	 */
	setTestRun (state, o) {
		if (o != null) {

			const testRunId = o.id
			let testRun = state.map[testRunId]

			// add to the map if it doesn't exist
			if (!testRun) {
				Vue.set(state.map, testRunId, o) // add to the map
				testRun = state.map[testRunId]
			}

			// update the report
			if (o.result) {
				testRun.result = o.result
				testRun.update(o.result)
			}
		}
	},
	/**
	 * Purge old test runs from the store
	 * @param state
	 */
	purgeOldTestRuns (state) {

		// convert from map of access times to array
		let arr = []
		const testRunIDs = Object.keys(state.accessTimestamp)
		testRunIDs.forEach((id) => {
			const accessTimestamp = state.accessTimestamp[id]
			arr.push({id, accessTimestamp})
		})

		// sort from oldest to newest
		arr = arr.sort((a, b) => {
			return a.accessTimestamp < b.accessTimestamp ? -1 : 1
		})

		// remove
		const removeN = arr.length - CACHE_LIMIT
		if (removeN > 0) {
			const remove = arr.splice(0, removeN)
			remove.forEach((o) => {
				Vue.delete(state.map, o.id)
				Vue.delete(state.accessTimestamp, o.id)
			})
		}

	},
	/**
	 * Update list of running tests
	 * @param state
	 * @param testRunList
	 */
	updateTestRuns (state, testRunList) {
		if (testRunList != null && Array.isArray(testRunList)) {
			// update each test run
			testRunList.forEach((item) => {
				const testRunId = item.id
				let testRun = state.map[testRunId]
				if (testRun == null) {
					testRun = !(item instanceof TestRun) ? new TestRun(item) : item

					// Add test run to store
					Vue.set(state.map, testRunId, testRun)
				} else {
					// Update test run
					testRun.update(item)
				}
			})
		}
	},
	updateTestRun (state, data: Partial<TestRun>) {
		const testRun = state.map[data._oid]
		if (testRun == null) {
			return
		}

		Object.assign(testRun, data)
	},
	updateConcurrencySets (state, concurrencySets) {
		state.concurrencySets = concurrencySets
	},
}

const getters: GetterTree<State, RootState> = {
	testRunList:         (state) => _.values(state.map),
	runningTests:        (state) => Object.values(state.map).filter(testRun => testRun.isPending),
	runningTestsGrouped: (state) => {
		const concurrencySets = state.concurrencySets.map((concurrencySet) => ({
			...concurrencySet,
			testRuns: (concurrencySet.testRunIDs ?? []).map((testRunID) => state.map[testRunID]),
		}))

		// merge concurrency sets with the same browsers
		return concurrencySets.reduce((acc, concurrencySet) => {
			const existingConcurrencySet = acc.find((existingConcurrencySet) => _.isEqual(existingConcurrencySet.browsers, concurrencySet.browsers))

			if (existingConcurrencySet) {
				existingConcurrencySet.total += concurrencySet.total
				existingConcurrencySet.running += concurrencySet.running
				existingConcurrencySet.testRunIDs.push(...concurrencySet.testRunIDs)
			} else {
				acc.push(concurrencySet)
			}

			return acc
		}, [])
	},
}

const actions = {
	// @todo: clear test runs from memory if they are no longer relevant to reduce memory footprint
	//
	fetchTestRun ({state, commit, dispatch}, id: string) {

		let deferred

		// do we have a cached copy of the test run
		// is the test run complete?
		const testrun: TestRun | undefined = state.map[id]

		const statuses: TestRunStatus[] = [
			TEST_RUN_STATUS.QUEUED,
			TEST_RUN_STATUS.CREATED,
			TEST_RUN_STATUS.INIT,
			TEST_RUN_STATUS.PENDING,
		]

		const isComplete = testrun && !statuses.includes(testrun.status)

		if (testrun && isComplete && testrun.steps.length > 0) {
			deferred = Promise.resolve(testrun)
		} else {
			deferred = api.project.testrun.get({id: id}).then((data) => {

				if (!data.result) {
					throw new Error("missing 'result' from response")
				}

				commit("setTestRun", new TestRun(data.result))

				return state.map[id]

			})
		}

		// clear old objects to save memory
		state.accessTimestamp[id] = (new Date()).getTime()
		commit("purgeOldTestRuns")

		return deferred
	},
	stopTestRun ({state, commit}, id: string) {

		// send the request to stop the test
		return api.project.testrun.stop(id)
	},
	async fetchRunningTestRuns ({state, commit, dispatch, rootGetters, rootState}) {
		const project = rootGetters["workspace/activeProject"]
		const space = rootState.spaces.map[project.spaceID]

		// If there is no previous request, do not request again
		if (space == null || project == null || state.isFetching) {
			return
		}

		const projectID = project.id // save reference to projectID in case user changes projects midway before request returns

		// set the flag to true so that it will not request again during the
		// next iteration
		commit("setIsFetching", true)

		// todo sort test runs by creation time
		try {
			const res = await api.project.testrun.list.running({
				projectID, // Added for impersonate of owner
			})

			if (!res.result) {
				throw new Error("Missing `result` object from response")
			}

			const testRuns = res.result.map(item => ({
				...item,
				id:       item._oid,
				filePath: item.name,
			}))

			const spaceFeatures = space.features ?? {}
			const concurrencySets = res.concurrencySets ?? []
			const testRunCredits = res.testRunCredits ?? 0

			if (res.concurrencySets === undefined) {
				// Use Legacy API support
				concurrencySets.push({
					browsers:   spaceFeatures.browsers,
					total:      res.concurrency ?? 1,
					running:    testRuns.length,
					testRunIDs: testRuns.map((testRun) => testRun._oid),
				})
			}

			// Update Store
			commit("updateTestRuns", testRuns)
			commit("updateConcurrencySets", concurrencySets)
			commit("spaces/update", {
				spaceID: space._oid,
				testRunCredits,
			}, {root: true})
		} catch (e) {
			if (projectID === "") {
				// Expected behaviour when projectID is empty
				return
			}

			console.error("Error retrieving running tests:", e)
		}

		commit("setIsFetching", false)
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
