import {formatDuration} from "@uilicious/cake-ui"

let installed = false

function install(Vue){
	if(installed){
		return
	}

	// Usage {{ testRun.totalTime | duration(2) }}
	Vue.filter("duration", function (value, precision /* precision of seconds */) {
		if (typeof value !== "number" || isNaN(value) || !isFinite(value)) {
			return ""
		}
		return formatDuration(value, precision)
	})

	installed = true

}

export default {
	install
}