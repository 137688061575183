import $api from "@uil/api"
import $user, {User} from "@/user"
import * as Intercom from "@uil/analytics/intercom"
import * as Analytics from "@uil/analytics/module"
import Vue from "vue"
import {store} from "@/store"
import router from "@/router"
import permissions from "@/permissions"

import {TourEvents} from "@uilicious/cake-ui"

import SubscriptionAlertModal from "@/components/subscription/SubscriptionAlertModal.vue"
import {OnPremiseConfig} from "@/@types/studio"
import {hasSpaceFeature} from "@/utils/feature"
import {setupTourEventHandlers} from "@/tours"
import {eventHub} from "@/plugins/eventHub"

export function run (app): Promise<any> {

	// get on premise config
	return $api.config.premise().then(async (onPremiseConfig) => {

		// now initialise studio
		initialiseStudio(app, onPremiseConfig, $user)
		await initialiseAccountSettings(app)
		initialiseWidgets($user)

	}).catch((err) => {
		console.error("Error getting app configuration: ", err)
		throw err
	})
}

function initialiseStudio (app, onPremiseConfig: OnPremiseConfig, user: User) {

	if (onPremiseConfig && onPremiseConfig.onPremise) {
		console.info("Installation: Self-hosted")
	}

	Vue.mixin({
		beforeCreate () {
			// Expose to all components
			this.$onPremise = onPremiseConfig.onPremise
			this.$onPremiseConfig = onPremiseConfig
			this.$hasSpaceFeature = hasSpaceFeature
		},
	})

	// Root Vue Component
	app.$vm = new Vue({
		// options:
		store,
		router,
		// custom plugins
		// @ts-ignore
		permissions,
		globalNotification: {
			status: null,
		},
		provide () {
			return {
				onPremise:       onPremiseConfig.onPremise,
				onPremiseConfig: onPremiseConfig,
			}
		},
		// lifecycle hooks
		created () {
			//--------------------------------------------------
			// Register root methods
			//--------------------------------------------------

			// go to login page
			//this.$redirectToLoginPage = window.STUDIO.redirectToLoginPage;

			// perform logout
			this.$logout = function () {

				// components such as the chat widget needs to handle logout
				this.$eventHub.$emit("beforeLogout")

				// add some delay before logging out
				window.setTimeout(function(){
					$api.account.logout().then(() => {
						console.info("User has been logged out.")

						// Redirect to login page
						let url = __ACCOUNT_PORTAL_HOST__
						if (!url.endsWith("/")) {
							url = url + "/"
						}
						url = url + "?logout=1"
						location.href = url

					}).catch((err) => {
						console.error("Error logging out: ", err)
					})
				}, 1000)

			}

			// redirect to not found page
			// this.$redirectToNotFoundPage = function(err) {
			// 	// log
			// 	if (err) {
			// 		console.error(err)
			// 	}
			// 	// redirect
			// 	if (this.$router) {
			// 		this.$router.push({path: "/notFound", query: {path: this.$router.currentRoute.fullPath}})
			// 	} else {
			// 		location.href = "/notFound?path=" + encodeURIComponent(location.href)
			// 	}
			// }

			this.$alertIfInactiveSubscription = function (subscription) {

				// skip checks for on premise
				if(this.$onPremise){
					return true;
				}

				// error if missing subscription
				if (!subscription) {
					this.$modal.open(SubscriptionAlertModal, {
						props: {
							message: "You do not have an active subscription for this space.",
						},
					})

					return false
				}

				// error if subscription is neither trialing nor active
				if (subscription.status !== "trialing" && subscription.status !== "active") {
					this.$modal.open(SubscriptionAlertModal, {
						props: {
							title:   "Your subscription has ended",
							message: `Your ${subscription.trialEnd ? "trial" : "subscription"} has ended.`,
						},
					})
					return false
				}

				return true
			}

			setupTourEventHandlers(eventHub)
		},
		mounted () {
			// fade out the loading animation
			setTimeout(() => {
				document.getElementsByClassName("app-progression")[0].setAttribute(
					"class", "app-progression app-progression--fade")
				setTimeout(() => {
					document.getElementsByClassName("app-progression")[0].setAttribute(
						"class", "app-progression app-progression--out")
				}, 300)
			}, 300)

			//If the enterprise trail is ending in 7 days then we will display the
			//global message that the trail is expiring in x number of days
			// @todo: refactor this to use the SubscriptionNotice component instead of the global message component.
			if (this.$onPremise) {
				const expiryTime = this.$onPremiseConfig.expiry
				if (expiryTime !== null) {
					const currentMillis = new Date().getTime()

					const ONE_DAY_MILLIS = 86400000
					const diffDays = Math.ceil(
						(expiryTime - currentMillis) / ONE_DAY_MILLIS)

					const TRIAL_ENDING_NOTIFICATION_PERIOD_DAYS = 7
					if (diffDays < 0) {
						this.$globalNotification.message = "Your trial has ended, please contact UI-licious to purchase a license."
					} else if (diffDays <= TRIAL_ENDING_NOTIFICATION_PERIOD_DAYS) {
						this.$globalNotification.message = "Your trial is ending in " +
							diffDays + " days"
					}
				}
			}
		},
	})

	// mount app
	app.$vm.$mount("#main")

}

function initialiseWidgets (user: User) {
	// Intercom
	Intercom.install(!user.impersonated)
	Intercom.login(user)

	// Google Analytics
	// GA.install(!user.impersonated)
	// GA.setUserId(user._oid)

	Analytics.install(user)
}

/**
 * Initalise account settings.
 * This will get account settings (e.g. autosave, completedTours, default dataset)
 * from local storage (as a offline cache) and from the server
 * @param app
 */
async function initialiseAccountSettings (app) {

	// (legacy support)
	// get account settings from local storage
	if (window.localStorage.getItem("autosave") !== null) {
		this.$store.dispatch("accountSettings/update", {
			autosave: window.localStorage.getItem("autosave") === "true",
		})
		window.localStorage.removeItem("autosave")
	}

	// (legacy support)
	// get default dataset from local storage
	Object.keys(window.localStorage).filter((keys) => {
		return keys.endsWith("/dataset/default")
	}).forEach((key) => {
		const groups = key.match("(user/project/)(?<projectID>.*)(/dataset/default)").groups
		const projectID = groups.projectID
		let defaultDataset = window.localStorage.getItem(key)
		app.store.commit("accountSettings/updateProjectSettings", {
			projectID, defaultDataset
		})
		window.localStorage.removeItem(key)
	})

	// get account settings from the server
	return store.dispatch("accountSettings/load")
}
