var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.chatEnabled
    ? _c("div", { attrs: { id: "chat", "data-chat-open": _vm.isChatOpen } }, [
        _c(
          "button",
          {
            attrs: {
              id: "chat-toggle-button",
              "aria-label": "Chat with support",
            },
            on: {
              click: function ($event) {
                _vm.isChatOpen = true
              },
            },
          },
          [_c("i", { staticClass: "fa fa-comment" })]
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "chat-window-container" } }, [
          _c("i", {
            staticClass: "chat-window-container-empty-state-icon fa fa-comment",
          }),
          _vm._v(" "),
          _c("iframe", {
            ref: "chatWindow",
            attrs: {
              id: "chat-window",
              title: "Chat window",
              name: "Chat window",
              src: "/chat.html",
              "data-website-id": _vm.websiteId,
              "data-user-email": _vm.$user.email,
              "data-user-name": _vm.$user.name,
              "data-user-oid": _vm.$user._oid,
              frameborder: "0",
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: {
                id: "chat-window-close-btn",
                "aria-label": "Close chat",
              },
              on: {
                click: function ($event) {
                  _vm.isChatOpen = false
                },
              },
            },
            [_vm._v("×")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }