//--------------------------------------------------
// STATIC DEPENDENCIES
//--------------------------------------------------
import Vue from "vue";
import VueRouter from "vue-router";

import { NotFoundErrorPage, StyleGuide } from "@uilicious/cake-ui";

import { store } from "@/store";

import * as GA from "@uil/analytics/google_analytics";
// import * as Intercom from "@uil/analytics/intercom";
// import { toggleChatWidget } from "@uil/analytics/intercom";

import { default as permissions, PERMISSIONS } from "@/permissions";
import { default as $user } from "@/user";

//--------------------------------------------------
// Override the resolve method
// - to add impersonation query to all routes
//--------------------------------------------------
VueRouter.prototype._resolve = VueRouter.prototype.resolve;
VueRouter.prototype.resolve = function (location, current, append) {
	if ($user.impersonated) {
		if (typeof location === "string") {
			if (location.indexOf("impersonate=") === -1) {
				if (location.indexOf("?") === -1) {
					location = location + "?";
				}
				location = "&impersonate=" + $user._oid;
			}
		} else if (typeof location === "object") {
			location.query = location.query || {};
			location.query.impersonate = $user._oid; // add the impersonation query param
		}
	}

	return this._resolve.apply(this, [location, current, append]);
};

//--------------------------------------------------
// Override the resolve method
// - to add impersonation query to all routes
//--------------------------------------------------

VueRouter.prototype._push = VueRouter.prototype.push;
VueRouter.prototype.push = function (to) {
	return this._push(to).catch((e) => {
		// suppress errors like:
		// - "Redirected when going from "/a" to "/b" via a navigation guard"
		//   - this happens with router.push, and is not an issue, but updated version of vue-router complains about it
		//   - see "https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a"
		let ignoredError =
			/^Redirected when going from .* to .* via a navigation guard/gi.test(e.message) ||
			e.name === "NavigationDuplicated"; // when you click on a link that leads to the page that you are currently on
		if (!ignoredError) {
			throw e;
		}
	});
};

//--------------------------------------------------
// ROUTER
//--------------------------------------------------
Vue.use(VueRouter);

//--------------------------------------------------
// ROUTE NAMES
//--------------------------------------------------
export const ROUTE = {
	LEGACY: "legacy",
	ROOT: "root",
	STUDIO: "studio",
	//
	SPACES: "spaces",
	//
	PROJECT_LIST: "project-list",
	PROJECT_HOME: "project",
	//
	PROJECT_EDITOR: "project/editor",
	PROJECT_EDITOR_DOCUMENT: "project/editor/document",
	//PROJECT_EDITOR_TEST:                           "project/editor/test", //deprecated
	//PROJECT_EDITOR_FILE:                           "project/editor/file", //deprecated
	//
	PROJECT_SETTINGS_HOME: "project/settings",
	PROJECT_SETTINGS_GENERAL: "project/settings/general",
	PROJECT_SETTINGS_BRANDING: "project/settings/branding",
	PROJECT_SETTINGS_WEBDRIVER: "project/settings/webdriver",
	PROJECT_SETTINGS_ENVIRONMENT: "project/settings/environment",
	PROJECT_SETTINGS_ACCESS: "project/settings/access",
	//
	PROJECT_MONITORING_HOME: "project/monitoring",
	PROJECT_MONITORING_JOB_LIST: "project/monitoring/job-list",
	//
	PROJECT_MONITORING_JOB_DETAIL: "project/monitoring/job",
	PROJECT_MONITORING_JOB_DETAIL_SETTINGS: "project/monitoring/job/settings",
	PROJECT_MONITORING_JOB_DETAIL_TEST_RUN_LIST: "project/monitoring/job/testRun/list",
	PROJECT_MONITORING_JOB_DETAIL_TEST_RUN_REPORT: "project/monitoring/job/testRun/report",
	//
	PROJECT_TEST_RUN_HISTORY_HOME: "project/testRun/list",
	PROJECT_TEST_RUN_BILL_REPORT: "project/testRun/bill/report",
	EXPORT_TEST_RUN_REPORT: "export-report",
};

// document title:
// Spaces
// <more specific> | <less specific>
// <file name> | Editor | <project name>
// Editor | <project name>
// Monitoring | <project name>
// Settings | <project name>

//--------------------------------------------------
// Our routes
//--------------------------------------------------
const router = new VueRouter({
	mode: "history",
	base: __WEBSTUDIO_BASE_PATH__, // this will be replaced by webpack
	routes: [
		{
			name: ROUTE.ROOT,
			path: "",
			redirect: (to) => {
				return {
					name: ROUTE.SPACES,
				};
			},
		},
		// path: /studio
		// LEGACY SUPPORT
		// - base path will be different for different environments - e.g. on-prem instances may have a different base path
		// - /studio will be dropped in the cloud edition because of issues with managing the js/css assets output and reference paths,
		//   so to keep things simple, this will simply be dropped altogehter
		// - here, we'll detect any usages of this legacy path and replace it with the right one
		{
			name: ROUTE.LEGACY,
			path: "/studio*",
			redirect: (to) => {
				let href = router.resolve({
					path: to.path.substr(7),
					hash: to.hash,
					query: to.query,
					meta: to.meta,
				}).href;
				// fully reload the page instead of using router to redirect
				// because otherwise this will cause some problems if there's more route replacements needed
				// e.g. needing to replace "/editor/test/:filePath" with "/editor/:filePath"
				window.location.href = href;
			},
		},
		// path: /export-report
		{
			name: ROUTE.EXPORT_TEST_RUN_REPORT,
			path: "/export-report",
			component: () => {
				return import("./views/export-report");
			},
		},
		//----------------------------------------------
		// path: /
		//----------------------------------------------
		{
			path: "/",
			meta: {
				title: "UI-licious",
			},
			component: () => {
				return import("./views/index.vue");
			},
			redirect: (to) => {
				return {
					name: ROUTE.SPACES,
				};
			},
			children: [
				//----------------------------------------------
				// path: /project
				//----------------------------------------------
				//{
				//	path:     "",
				//	redirect: ROUTE.PROJECT_LIST
				//},
				// path: /project
				{
					name: ROUTE.PROJECT_LIST,
					path: "project",
					beforeEnter(to, from, next) {
						return next({
							path: "/space",
						});
					},
				},
				// path: /project/:projectId
				{
					name: ROUTE.PROJECT_HOME,
					path: "project/:projectId",
					meta: {
						title: ":projectName・UI-licious",
					},
					component: () => {
						return import("./views/project/_projectID");
					},
					redirect: {
						name: ROUTE.PROJECT_EDITOR,
					},
					children: [
						// path: /project/:projectId/editor
						{
							path: "editor",
							component: () => {
								return import("./views/project/_projectID/editor");
							},
							redirect: {
								name: ROUTE.PROJECT_EDITOR, // Make sure to redirect to its the blank document pane, otherwise the child router view will not be rendered at all
							},
							children: [
								// Loads the blank document pane
								{
									name: ROUTE.PROJECT_EDITOR,
									path: "",
									component: () => {
										return import("./components/project/editor/DocumentPane/DocumentPane.vue");
									},
									meta: {
										title: "Editor・:projectName・UI-licious",
									},
									beforeEnter(to, from, next) {
										const selectedNode = store.getters["workspace/selectedNode"];

										// open the last opened file
										// - this is so that when you navigate from Monitoring/Run tabs back to Editor, the we'll open the same file again
										if (selectedNode) {
											return next({
												name: ROUTE.PROJECT_EDITOR_DOCUMENT,
												params: {
													projectId: to.params.projectId,
													filePath: selectedNode.path,
												},
												hash: to.hash,
												query: to.query,
												meta: to.meta,
											});
										}

										// open the workspace panel
										store.commit("editor/setActiveTab", {
											side: "left",
											tabId: "workspace",
										});

										return next();
									},
								},
								// LEGACY SUPPORT
								// - this must come BEFORE ":filePath" path
								{
									path: "test/*", // LEGACY PATH - in case some users save these old routes
									redirect: (to) => {
										return {
											name: ROUTE.PROJECT_EDITOR_DOCUMENT,
											params: {
												projectId: to.params.projectId,
												filePath: to.params[0], // this is the file name
											},
											hash: to.hash,
											query: to.query,
											meta: to.meta,
										};
									},
								},
								// Load the file
								{
									name: ROUTE.PROJECT_EDITOR_DOCUMENT,
									meta: {
										title: ":fileName・Editor・:projectName・UI-licious",
									},
									path: ":filePath",
									component: () => {
										return import("./components/project/editor/DocumentPane/DocumentPane.vue");
									}
								},
								// Fallback - this happens when folder separators "/" are not
								//   properly url encoded to "%2F"
								{
									path: "*",
									redirect: (to) => {
										return {
											name: ROUTE.PROJECT_EDITOR_DOCUMENT,
											params: {
												projectId: to.params.projectId,
												filePath: to.params[0], // this is the file name
											},
											hash: to.hash,
											query: to.query,
											meta: to.meta,
										};
									},
								},
							],
						},
						//// path: /project/:projectId/settings
						{
							path: "settings",
							meta: {
								title: "Settings・:projectName・UI-licious",
							},
							component: () => {
								return import("./views/project/_projectID/settings");
							},
							beforeEnter(to, from, next) {
								let projectId = to.params.projectId;
								let allowed = permissions.isAllowed(PERMISSIONS.PROJECT.MANAGE).on({ project: projectId });
								// if not allowed, redirect to project home
								if (!allowed) {
									return next({
										name: ROUTE.PROJECT_HOME,
										params: {
											projectId: projectId,
										},
									});
								}
								return next();
							},
							children: [
								// path: /project/:projectId/settings
								{
									name: ROUTE.PROJECT_SETTINGS_HOME,
									path: "",
									redirect: {
										name: ROUTE.PROJECT_SETTINGS_GENERAL,
									},
								},
								// path: /project/:projectId/settings/general
								{
									name: ROUTE.PROJECT_SETTINGS_GENERAL,
									path: "general",
									meta: {
										title: "Settings・:projectName・UI-licious",
									},
									component: () => {
										return import("./views/project/_projectID/settings/general");
									},
								},
								// path: /project/:projectId/settings/branding
								{
									name: ROUTE.PROJECT_SETTINGS_BRANDING,
									path: "branding",
									meta: {
										title: "Branding・:projectName・UI-licious",
									},
									component: () => {
										return import("./views/project/_projectID/settings/branding");
									},
								},
								// path: /project/:projectId/settings/webdriver
								{
									name: ROUTE.PROJECT_SETTINGS_WEBDRIVER,
									path: "webdriver",
									meta: {
										title: "Webdriver Configuration・:projectName・UI-licious",
									},
									component: () => {
										return import("./views/project/_projectID/settings/webdriver");
									},
								},
							],
						},
						// path: /project/:projectId/monitoring
						{
							name: ROUTE.PROJECT_MONITORING_HOME,
							path: "monitoring",
							meta: {
								title: "Monitoring・:projectName・UI-licious",
							},
							component: () => {
								return import("./views/project/_projectID/monitoring");
							},
							redirect: {
								name: ROUTE.PROJECT_MONITORING_JOB_LIST,
							},
							children: [
								// path: /project/:projectId/monitoring/job
								{
									path: "job",
									component: () => {
										return import("./views/project/_projectID/monitoring/job");
									},
									redirect: {
										name: ROUTE.PROJECT_MONITORING_JOB_LIST,
									},
									children: [
										{
											name: ROUTE.PROJECT_MONITORING_JOB_LIST,
											path: "list",
											component: () => {
												return import("./views/project/_projectID/monitoring/job/list");
											},
										},
										// path: /project/:projectId/monitoring/job/:jobId
										{
											name: ROUTE.PROJECT_MONITORING_JOB_DETAIL,
											path: ":jobId",
											component: () => {
												return import("./views/project/_projectID/monitoring/job/_jobID");
											},
											redirect: {
												name: ROUTE.PROJECT_MONITORING_JOB_DETAIL_TEST_RUN_LIST,
											},
											children: [
												// path: /project/:projectId/monitoring/job/:jobId/settings
												{
													name: ROUTE.PROJECT_MONITORING_JOB_DETAIL_SETTINGS,
													path: "settings",
													component: () => {
														return import("./views/project/_projectID/monitoring/job/_jobID/settings");
													},
												},
												// path: /project/:projectId/monitoring/job/:jobId/runs
												{
													name: ROUTE.PROJECT_MONITORING_JOB_DETAIL_TEST_RUN_LIST,
													path: "testRun/list",
													component: () => {
														return import("./views/project/_projectID/monitoring/job/_jobID/testRun/list.v2.vue");
													},
												}
											],
										},
									],
								},
								// path: /project/:projectId/monitoring/testRun/:testRunId
								{
									name: ROUTE.PROJECT_MONITORING_JOB_DETAIL_TEST_RUN_REPORT,
									path: "testRun/:testRunId",
									component: () => {
										return import("./views/project/_projectID/monitoring/testRun/_testRunID");
									},
								},
								// invalid sub-paths
								{
									path: "*",
									redirect: {
										name: ROUTE.PROJECT_MONITORING_JOB_LIST,
									},
								},
							],
						},
						// path: /project/:projectId/testrun
						// PROJECT TEST RUN HISTORY
						{
							name: ROUTE.PROJECT_TEST_RUN_HISTORY_HOME,
							path: "testRun/list",
							meta: {
								title: "Runs・:projectName・UI-licious",
							},
							component: () => {
								return import("./views/project/_projectID/testRun/list.v2.vue");
							},
						},
						// TEST RUN BILL REPORT
						{
							// todo: name, meta title
							name: ROUTE.PROJECT_TEST_RUN_BILL_REPORT,
							path: "testRun/bill/:billId",
							component: () => {
								return import("./views/project/_projectID/testRun/bill/_billID");
							},
						},
						// invalid sub-paths
						{
							path: "*",
							redirect: {
								name: ROUTE.PROJECT_EDITOR,
							},
						},
					],
				},
				//----------------------------------------------
				// path: /spaces
				//----------------------------------------------
				{
					name: ROUTE.SPACES,
					path: "/space",
					meta: {
						title: "Spaces・UI-licious",
						hideIntercom: true,
					},
					component: () => {
						return import("./views/spaces");
					},
					beforeEnter(to, from, next) {
						return next();
					},
				},
			],
		},
		//----------------------------------------------
		// Style Guide
		//----------------------------------------------
		{
			path: "/styleGuide",
			meta: {
				title: "Style Guide・UI-licious",
			},
			component: StyleGuide,
		},
		//----------------------------------------------
		// Not found pages
		//----------------------------------------------
		{
			name: "404",
			path: "/notFound",
			meta: {
				title: "404 Not Found・UI-licious",
			},
			component: NotFoundErrorPage,
		},
		{
			path: "*",
			redirect() {
				return {
					path: "/notFound",
				};
			},
		},
	],
});

//--------------------------------------------------
// ROUTER GUARDS
//--------------------------------------------------
const excludedRoutes = [ROUTE.ROOT, ROUTE.LEGACY, ROUTE.EXPORT_TEST_RUN_REPORT];

let spacesPromise = null;

// Before
router.beforeEach(async (to, from, next) => {
	if ($user && $user.impersonated) {
		// add the impersonation query string
		if (!to.query.impersonate) {
			to.query.impersonate = $user._oid;
			let { path, params, hash, query } = to;
			return next({ path, params, hash, query, replace: true });
		}
	}

	const requiresSpaces = !excludedRoutes.includes(to.name);
	if (!requiresSpaces) {
		return next();
	}

	// Route requires spaces, ensure it's loaded
	try {
		await fetchSpacesAndProjects();
	} catch (error) {
		console.error("Error fetching spaces and projects: ", error);
		return next(error);
	}

	if (typeof to.meta.canAccess === "function" && !to.meta.canAccess(to)) {
		// Access denied, return to home
		return next({
			name: ROUTE.ROOT,
		});
	}

	return next();
});

function fetchSpacesAndProjects() {
	if (spacesPromise == null) {
		// Cache the promise so that it only fetches once
		spacesPromise = store.dispatch("spaces/fetchSpaces");
	}

	return spacesPromise;
}

function resolveDocumentTitle(to) {
	let title = "UI-licious";

	// get title for this page or it's parent views
	for (let i = to.matched.length - 1; i > 0; i--) {
		if (to.matched[i].meta.title) {
			title = to.matched[i].meta.title;
			break;
		}
	}

	// resolve :projectName variable
	if (title.includes(":projectName")) {
		try {
			let projectId = to.params.projectId;
			let project = store.state.projects.map[projectId];
			title = title.replace(":projectName", project.name);
		} catch (e) {
			console.error("Error resolving :projectName " + e);
			title = title.replace(":projectName", "");
		}
	}

	// resolve :fileName variable
	if (title.includes(":fileName")) {
		try {
			let filePath = to.params.filePath;
			let tokens = filePath.split("/");
			let fileName = tokens[tokens.length - 1];
			title = title.replace(":fileName", fileName);
		} catch (e) {
			console.error("Error resolving :fileName ", e);
			title = title.replace(":fileName", "");
		}
	}

	// todo: resolve jobName
	// todo: resolve testName

	window.document.title = title;
}

// After
router.afterEach((to, from) => {

	resolveDocumentTitle(to);

	// toggleChatWidget(to.meta.hideIntercom === true);

	// tracking
	try {
		// Intercom.updatePageChange();
		GA.trackPageView(to.path);
	} catch (e) {
		console.error(e);
	}

});

export default router;
