//-------------------------------
// STATE
//-------------------------------

const state = {
	testRunId: null
}

//-------------------------------
// MUTATIONS
//-------------------------------

const mutations = {
	setTestRunId(state, testRunId) {
		state.testRunId = testRunId
	}
}

//-------------------------------
// GETTERS
//-------------------------------

const getters = {
	getTestRun(state, getters, rootState) {

		let testRunId = state.testRunId

		if (_.isNil(testRunId)) {
			return null
		}

		let testRun = rootState.testRuns.map[testRunId]
		if (_.isNil(testRun)) {
			return null
		}
		return testRun
	}
}

//-------------------------------
// STORE
//-------------------------------

export default {
	namespaced: true,
	state,
	mutations,
	getters
}
