//--------------------------------------------------
// Static dependencies
//--------------------------------------------------
import Vue from "vue"
import Space from "@uil/models/Space"
import api from "@uil/api"
import * as Intercom from "@uil/analytics/intercom"
import {default as permissions, SPACE_PERMISSIONS_FOR_ROLE} from "@/permissions"
import {ActionTree, GetterTree, MutationTree} from "vuex"
import {RootState} from "@/store/types"
import {sortBy} from "lodash"

interface Invitation {
	invitationID: string;
	spaceID: string;
	name: string;
	userRole: string;
}

export interface State {
	initialising: boolean;
	map: Record<string, Space>;
	invitations: Invitation[];
}

const state: State = {
	initialising: true,
	map:          {},
	invitations:  [],
}

const mutations: MutationTree<State> = {
	setInitialising(state, initialising: boolean) {
		state.initialising = initialising
	},
	update(state, {spaceID, ...data}) {
		const space = state.map[spaceID]
		Object.assign(space, data)

		Vue.set(state.map, spaceID, space)
	},
	setSpace (state, space: Space) {
		if (typeof space !== "undefined" && space !== null) {
			Vue.set(state.map, space._oid, space)
		}
	},
	setSpaces (state, spaceList) {
		if (Array.isArray(spaceList)) {
			// clear all
			const spaceIDs = Object.keys(state.map)
			spaceIDs.forEach((spaceID) => {
				Vue.delete(state.map, spaceID)
			})
			// set all
			spaceList.forEach((space) => {
				if (typeof space !== "undefined" && space !== null) {
					Vue.set(state.map, space._oid, space)
				}
			})
		}
	},
	deleteSpace (state, spaceID) {
		if (typeof spaceID === "string") {
			Vue.delete(state.map, spaceID)
		}
	},
	setInvitations (state, invitations: Invitation[]) {
		// use splice to clear and add the invitations
		state.invitations.splice(0, state.invitations.length, ...invitations)
	},
	removeMember (state, {spaceID, email}: {spaceID: string; email: string}) {
		const space = state.map[spaceID]
		if (space) {
			const index = space.members.findIndex((member) => member.email === email)
			if (index !== -1) {
				space.members.splice(index, 1)
			}
		}
	}
}
const getters: GetterTree<State, RootState> = {
	listSpaces (state): Space[] {
		return Object.values(state.map)
	},
	listSpacesOrderedByName (state, getters) {
		const spaces: Space[] = getters.listSpaces
		return sortBy(spaces, ["name"])
	},
}

const actions: ActionTree<State, RootState> = {
	fetchSpaces ({commit, state, dispatch}) {
		// get list of spaces
		return api.space.list().then((data) => {
			commit("setInitialising", false)

			// Convert into Space objects
			const spaces = data.result.map((s) => new Space(s))

			// Add the personal space for legacy projects
			spaces.push(
				new Space({
					_oid:     "",
					name:     "Personal",
					userRole: "owner",
				}),
			)

			// for each space...
			const deferredFetchSpaces = []
			spaces.forEach((space) => {

				// This is for the personal project that exist
				// as it does not have any oid at all and it is used
				// to store legacy projects that does not belong to any spaces
				if (!space._oid) {
					return
				}

				// set permissions for the space
				const spacePermissions = SPACE_PERMISSIONS_FOR_ROLE[space.userRole] || []
				permissions.setPermissions(spacePermissions).on({space: space._oid})

				// fetch space information
				const deferred = dispatch("fetchSpace", space._oid)
				deferredFetchSpaces.push(deferred)
			})

			// wait for all space information to be retrieved
			return Promise.all(deferredFetchSpaces)
		})
		// let caller handle the exception

	},
	fetchSpace ({commit, dispatch}, spaceID) {

		// get information about a space
		let space
		return api.space.info(spaceID).then((data) => {

			if (typeof data.result === "undefined" || data.result == null) {
				throw new Error("missing `result` from response")
			}

			// create the Space object, commit to the store
			space = new Space(data.result)
			commit("setSpace", space)

			// set permissions for the space
			const spacePermissions = SPACE_PERMISSIONS_FOR_ROLE[space.userRole] || []
			permissions.setPermissions(spacePermissions).on({space: space._oid})

			// set project for each space
			const projects = data.result.projects
			return dispatch("projects/setSpaceProjects", {space, projects}, {root: true})

		}).then(() => {
			// finally return the space
			return space
		})
	},
	addSpace ({commit, dispatch}, {name}) {
		return api.space.create({name}).then((data) => {
			const spaceID = data.result
			if (typeof spaceID === "undefined" || spaceID == null || spaceID === "") {
				throw new Error("missing `result` from response")
			}

			// create the Space object, commit to the store
			const createdSpace = new Space({_oid: spaceID, name: name, userRole: "owner"})
			commit("setSpace", createdSpace)

			// track event
			Intercom.trackEvent("Created Space")

			// get the space (for additional information, e.g. userQuota)
			return dispatch("fetchSpace", spaceID)
		})
	},
	renameSpace ({commit, state}, {spaceID, name}) {

		const space = state.map[spaceID]

		return new Promise((resolve, reject) => {

			// does space exist?
			if (!space) {
				return reject(new Error("Space not found"))
			}

			// Validate the name
			// - must not be empty
			// - must not contain illegal characters
			if (typeof name !== "string" || name === "") {
				return reject(new Error("Space name cannot be empty"))
			}

			const VALID_NAME_FORMAT = /^[^\\/?%\*:\"\|><]*$/
			if (!VALID_NAME_FORMAT.test(name)) {
				return reject(new Error("Space name cannot contain any of the following characters: <br> /\?%*:|<>\""))
			}

			// Send request to backend
			api.space.rename(spaceID, {name: name}).then((data) => {

				// error if missing result
				if (data.result == null) {
					throw new Error("missing `result` from response")
				}

				// commit changes to store
				space.name = name
				commit("setSpace", space)

				// resolve promise
				resolve(space)

			}).catch((err) => {
				// sanitise error for user
				console.error("Error renaming space - ", err)
				reject(new Error("Error renaming space, please try again later or contact support."))

			})

		})

	},
	fetchInvitations ({commit, state}) {
		return api.space.invitation.list().then((data) => {
			if (typeof data.result === "undefined" || data.result == null) {
				throw new Error("missing `result` from response")
			}

			// for each invitation get the invitation (because the current list API does not contain the inviter informaiton)
			const invitations = data.result
			const getInfoForAllInvitations = []
			invitations.forEach((invitation) => {
				let getInfoForInvitation
				if (invitation.invitationID) {
					getInfoForInvitation = api.space.invitation.info(invitation.invitationID)
				}
				getInfoForAllInvitations.push(getInfoForInvitation)
			})

			return Promise.all(getInfoForAllInvitations)
		}).then((data) => {
			const invitations = data.map((d) => d.result)
			commit("setInvitations", invitations)
		})
	},
	acceptInvitation ({commit, state, dispatch}, invitation) {
		return api.space.invitation.accept(invitation.invitationID).then((res) => {
			// fetch all the spaces again
			return dispatch("fetchSpaces").then(() => {
				// fetch all the invitations again
				return dispatch("fetchInvitations")
			}).catch((err) => {
				console.error("Error refreshing spaces or invitations: ", err)
			})
		})
	},
}

export default {
	namespaced: true,
	state:      state,
	mutations,
	getters,
	actions,
}
