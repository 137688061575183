export default {
	SPACE: {
		ADDED:   "space/added",
		UPDATED: "space/updated",
		DELETED: "space/deleted",
		MEMBER:  {
			ADDED:   "space/member/added",
			UPDATED: "space/member/updated",
			DELETED: "space/member/deleted",
		},
		INVITE: {
			ADDED:   "space/member/added",
			UPDATED: "space/member/updated",
			DELETED: "space/invite/deleted",
		},
	},
	PROJECT: {
		MODAL_OPENED:   "project/modalOpened",
		MODAL_CLOSED:   "project/modalClosed",
		CANCEL_EDITING: "project/cancelEditing",
		ADDED:          "project/added",
		UPDATED:        "project/updated",
		DELETED:        "project/deleted",
	},
	PROJECT_DELETED: "project_deleted", //TODO: refactor this
	DIRECTORY:       {
		MODAL_OPENED:          "directory/modalOpened",
		MODAL_CLOSED:          "directory/modalClosed",
		RENAME_NODE_TITLE_BAR: "directory/renameNodeTitleBar",
		UPDATED:               "directory/updated", // use this for add, update, and delete folder/file/test
		FETCHED:               "directory/fetched", // use this for add, update, and delete folder/file/test
		FILES_ADDED:           "directory/filesAdded", // file is added for upload to the directory
	},
	ENVIRONMENT: {
		SELECT: "environment/select",
	},
	SCRIPT: {
		READY:   "script/ready", // the script pane is ready to receive interactions
		SET:     "script/set",
		SAVE:    "script/save",
		CODEGEN: "script/codeGen",
	},
	TEST: {
		CREATED: "test/created",
	},
	TEST_RUN: {
		INIT:   "testRun/init",	// get the test script
		CREATE: "testRun/create", // create the test run, passing in the test script
		START:  "testRun/start", // start the test run
		STOP:   "testRun/stop", // stop the test run
	},
	PREVIEW_WINDOW: {
		BEFORE_DESTROY: "previewWindow/beforeDestroy",
		MOUNTED:        "previewWindow/mounted",
		RESET:          "previewWindow/reset",
	},
	MONITORING: {
		JOB_UPDATED: "monitoring/job_updated",
		JOB_DELETED: "monitoring/job_deleted",
		JOB_RAN:     "monitoring/job_ran",
	},
	JOB_TESTRUNS: {
		PAGE_UPDATE: "job_testrun/page_update",
	},
	TUTORIAL: {
		OPEN: "tutorial/open",
		IS_OPENED: "tutorial/is_opend",
	},
}
