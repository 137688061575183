let CONFIG = {
	CDN: {
		enabled: false,
		domain:  null
	},
	// expect: "https://api.uilicious.com/v2/project/testrun/image?id=:id&image=:image"
	url: ""
}

export default {
	config(opts) {
		if (typeof opts !== "undefined") {
			if (typeof opts === "object" && opts !== null) {
				CONFIG = Object.assign(CONFIG, opts)
			}
		} else {
			return CONFIG
		}

	},
	get(testRunId, testRunImage, width, useFallback = false) {

		if (!testRunId || !testRunImage) {
			return ""
		}

		// get screenshot from API server (this will always work, but is slower)
		let url = CONFIG.url.replace(":id", testRunId).replace(":image", encodeURIComponent(testRunImage))
		if (!CONFIG.CDN.enabled /* CDN is disabled */ || useFallback /* failed to load from CDN */) { 
			return url
		}

		// get the full-sized screenshot from the CDN (this will be faster)
		url = `${CONFIG.CDN.domain}/imgs/x/${testRunId}/${encodeURIComponent(testRunImage)}`

		// get the thumbnail of the screenshot from the CDN
		let breakpoints = [
			// CDN API requires breakpoints to be a multiple of 120
			// 1024, // not multiple of 120
			960,
			768, // not multiple of 120
			480,
			240
		]

		breakpoints.forEach((bp) => {
			// use breakpoint one size bigger than desired width
			if(bp >= width){
				url = `${CONFIG.CDN.domain}/imgs/${bp}x/${testRunId}/${encodeURIComponent(testRunImage)}`
			} else {
				return false; // break loop
			}
		})

		return url;

	}
}
