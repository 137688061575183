/**
 * Class representing a Space, which is a collection of Projects. A Space belongs to a Team, and should have an owner for adminstration and billing
 */
class Space {
	members = []

	constructor(o) {
		Object.assign(this,
			{
				_oid:         null,
				name:         "",
				subscription: null,
				features:     null,
				members:      [],
				userRole:     "", // current user's role in this space
			}
		)

		if (typeof o === "object") {
			Object.assign(this, o)

			// if projects are included, remove it
			delete this.projects
		}

	}

	get _oid() {
		return this.__oid
	}

	set _oid(value) {
		this.__oid = value
	}

	get name() {
		return this._name
	}

	set name(value) {
		this._name = value
	}

	get userRole() {
		return this._userRole
	}

	set userRole(userRole) {
		this._userRole = userRole
	}

	get subscription(){
		return this._subscription
	}

	set subscription(subscription) {
		this._subscription = subscription
	}

	get plan(){
		return this.subscription ? this.subscription.plan : null
	}

	get features(){
		return this._features
	}

	set features(features) {
		this._features = features
	}

	get memberCount() {
		return this.members?.length ?? 0
	}
}

export default Space
