import api from "@uil/api"
import DirectoryNode from "@uil/models/DirectoryNode"
import {AxiosError} from "axios"

export function flattenNodes (node) {
	const nodes = []

	if (node) {
		nodes.push(node)
		if (node.children && Array.isArray(node.children)) {
			node.children.forEach(child => {
				nodes.push(...flattenNodes(child))
			})
		}
	}

	return nodes
}

export async function uploadFile (
	projectId: string, node: DirectoryNode, signal: AbortSignal)
	: Promise<void> {
	return new Promise((resolve, reject) => {
		const onUploadProgress = ({loaded, total}) => {
			if (node.upload) {
				node.upload.status = "uploading"
				node.upload.progress = Math.round(loaded / total * 100)
			}
		}

		if (node.upload && node.upload.status === "aborted") {
			reject({
				message: "canceled", // throw the same error as the one thrown by the abort signal
			})
			return
		}

		if (!node.upload || node.upload.status !== "queued") {
			// Invalid upload state, don't upload
			return
		}

		const onUploadSuccess = () => {
			node.upload = null

			let parent = node.parent
			while (parent) {
				if (parent.upload) {
					parent.upload = null
				}
				parent = parent.parent
			}

			resolve()
		}

		// Perform the upload
		const formData = new FormData()
		formData.append("projectID", projectId)
		formData.append("filePath", node.path)

		if (node.upload.file) {
			formData.append("content", node.upload.file)
		}

		if (node.upload.overwrite) {
			formData.append("overwrite", "1")
		}

		api.project.file.put(formData, {
			onUploadProgress,
			signal,
		}).then(onUploadSuccess).catch(err => {
			if (err.message !== "canceled") {
				if (node.upload) {
					node.upload.status = "failed"
				}
			}

			reject(err)
		})
	})
}