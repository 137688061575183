import {colorHash} from "@uilicious/cake-ui"

/**
 * Singleton module to capture all user properties
 */

export class User {

	__oid: string
	email: string
	_name: string
	loginNameList: string[]
	impersonated: boolean
	isSuperUser: boolean
	emailVerified: boolean
	crispSig?: string
	profilePictureURL: string

	constructor () {
		this.__oid = null
		this.email = ""
		this.name = ""
		this.loginNameList = []
		this.impersonated = false
		this.isSuperUser = false
		this.emailVerified = false
		this.profilePictureURL = null
	}

	set (user: any) {
		if (typeof user !== "undefined" && user !== null) {
			Object.keys(user).forEach((prop) => {
				this[prop] = user[prop]
			})
		}
	}

	get _oid (): string {
		return this.__oid
	}

	set _oid (__oid: string) {
		if (!this.__oid) {
			// set only once
			this.__oid = __oid
		} else if (__oid !== this.__oid) {
			console.warn("User _oid already been set.")
		}
	}

	get accountID (): string {
		return this.__oid
	}

	set accountID (accountID) {
		this._oid = accountID
	}

	get name (): string {
		return this._name ? this._name : "Anonymous"
	}

	set name (name: string) {
		this._name = name
	}

	get initials () {
		return this.name.split(" ") // split by spaces
			.map((n) => n.substr(0, 1)) // get the first character
			.join("") // join the initials together
			.substr(0, 2) // limit to 2 characters
	}

	get color (): string {
		// based on the name, generate a color for this user
		return colorHash(this.name)
	}


}

const instance = new User()

export default instance
