const CHROME = {
	id:       "chrome",   // internal id for the browser
	name:     "Chrome", // browser name (customer facing)
	features: {
		regionsEnabled:   true, // is regions feature enabled for the browser
		userAgentEnabled: true,
	},
}

const FIREFOX = {
	id:       "firefox",   // internal id for the browser
	name:     "Firefox", // browser name (customer facing)
	features: {
		regionsEnabled:   true, // is regions feature enabled for the browser
		userAgentEnabled: true,
	},
}

const SAFARI = {
	id:       "safari",   // internal id for the browser
	name:     "Safari", // browser name (customer facing)
	features: {
		regionsEnabled:   false, // is regions feature enabled for the browser
		userAgentEnabled: false,
	},
}

const EDGE_CHROMIUM = {
	id:       "edgechromium",   // internal id for the browser
	name:     "Edge (Chromium)", // browser name (customer facing)
	features: {
		regionsEnabled:   true, // is regions feature enabled for the browser
		userAgentEnabled: false,
	},
}

const EDGE_LEGACY = {
	id:       "edge",   // internal id for the browser
	name:     "Edge (2019)", // browser name (customer facing)
	features: {
		regionsEnabled:   false, // is regions feature enabled for the browser
		userAgentEnabled: false,
	},
}

const IE_11 = {
	id:       "ie11",   // internal id for the browser
	name:     "IE 11", // browser name (customer facing)
	features: {
		regionsEnabled:   false, // is regions feature enabled for the browser
		userAgentEnabled: false,
	},
}

const CHROME_METAMASK = {
	id:       "chrome-metamask",   // internal id for the browser
	name:     "Metamask (using Chrome)", // browser name (customer facing)
	features: {
		regionsEnabled:   true,
		userAgentEnabled: true
	}
}

export const Browser = {
	[CHROME.id]:           CHROME,
	[FIREFOX.id]:          FIREFOX,
	[SAFARI.id]:           SAFARI,
	[EDGE_CHROMIUM.id]:    EDGE_CHROMIUM,
	[EDGE_LEGACY.id]:      EDGE_LEGACY,
	[IE_11.id]:            IE_11,
	[CHROME_METAMASK.id] : CHROME_METAMASK
}

export const BrowserList = [
	CHROME, FIREFOX, SAFARI, EDGE_CHROMIUM, EDGE_LEGACY, IE_11, CHROME_METAMASK
]

export const BrowserOptions = BrowserList.map(browser => ({
	value: browser.id,
	label: browser.name,
}))