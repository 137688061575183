let installed = false

export function formatTime(hhmm, timezoneOffset) {

	// convert "12:00" + "03:00" to "03:00 pm"
	if (typeof timezoneOffset === "undefined") {
		timezoneOffset = "00:00"
	}

	let hour = Number.parseInt(hhmm.split(":")[0])
	let min = hhmm.split(":")[1]

	if (hour < 12) {
		hhmm = `${hour}:${min} am` // so we get "2:00 am"
	} else if (hour === 12) {
		hhmm = `${hour}:${min} pm` // so we get "12:00 pm"
	} else {
		hhmm = `${hour - 12}:${min} pm` // so we get "2:00 pm"
	}

	return hhmm + " " + timezoneOffset

}

function install(Vue) {

	if (installed) {
		return
	}

	Vue.filter("hhmmz", formatTime)

	installed = true

}

export default {
	install,
}