//--------------------------------------------------
//
// Imports
//
//--------------------------------------------------

// Vendor
import "./vendor"

// Styles
import "@/style/app.scss"
import "@uilicious/cake-ui/dist/style.css"

// vue js
import Vue from "vue"

// UI-licious
import $api from "@uil/api" // new version of api using axios
import TestRunImage from "@uil/services/TestRunImage"

// setup
import * as SetupAPI from "@/setup/setupAPI"
import * as SetupVue from "@/setup/setupVue"
import * as DoAuth from "@/setup/doAuth"
import * as SetupStudio from "@/setup/setupStudio"

import * as DeveloperPreview from "@/utils/developerPreview"
import {redirectToLoginPage} from "@/utils/redirectToLoginPage"
import {printErrorOnScreen} from "@/utils/printErrorOnScreen"
import {Store} from "vuex"
import {User} from "@/user"
import * as LocalStorageSync from "@uil/util/LocalStorageSync"

//--------------------------------------------------
//
// STUDIO
//
//--------------------------------------------------
export interface Studio {
	developerPreview: {
		enable: () => void;
		disable: () => void;
		isEnabled: () => boolean;
	},
	redirectToLoginPage: (redirect?: boolean) => void;
	printErrorOnScreen: (error: any) => void;
	api: typeof $api;
	store: Store<any>;
	$vm: typeof Vue;
	user: User;
	localStorage: LocalStorageSync.LocalStorageSync;
}

const studio: Studio = {
	developerPreview: {
		enable:    DeveloperPreview.enableDeveloperPreview,
		disable:   DeveloperPreview.disableDeveloperPreview,
		isEnabled: DeveloperPreview.isDeveloperPreviewEnabled,
	},
	redirectToLoginPage: redirectToLoginPage,
	printErrorOnScreen:  printErrorOnScreen,
	api:                 $api,
	store:               null,
	$vm:                 null, // the vue instance
	user:                null,
	localStorage:        null
}

// expose as global
window.STUDIO = studio

//--------------------------------------------------
//
// Initialisation logs
//
//--------------------------------------------------
if (studio.developerPreview.isEnabled) {
	console.warn(
		"Developer Preview enabled. Warning: You are now seeing experimental features that we are currently testing, these are not stable, and you are advised against using them for serious stuff as of now.",
	)
}
console.info(process.env.NODE_ENV)
console.info("Welcome to UI-licious Studio!")
console.info("Version: ", __VERSION__) // actual value of __VERSION__ will be replaced by webpack
console.info("Deployed on: ", __BUILD_TIME__) // actual value of __BUILD_TIME__ will be replaced by webpack
console.info("Built with VueJS: ", Vue.version)

//--------------------------------------------------
//
// Setup application
//
//--------------------------------------------------

SetupAPI
	.run(studio)
	.then((studio) => {
		TestRunImage.config({
			CDN: {
				enabled: __IMAGE_CDN_ENABLED__,
				domain:  __IMAGE_CDN_DOMAIN__,
			},
			// expect: "https://api.uilicious.com/v2/project/testrun/image?id=:id&image=:image"
			url: $api._core.baseURL() + "/project/testrun/image/get?id=:id&image=:image",
		})

		return studio
	})
	.then(DoAuth.run)
	.then(SetupVue.run)
	.then(SetupStudio.run)
	.then(async () => {
		// import uilicious analytics library after the studio has been initialised
		const analytics_module = await import("@/../modules/marketing-analytics/lib/uilc_analytics")
		analytics_module.install(window, $api)
	})
	.catch((err) => {
		console.error("Error setting up app: ", err)
	})

