import gettingStarted from "@/tours/gettingStarted"
import type {TourConfig} from "@uilicious/cake-ui/src/plugins/Tour/src/types"
import Vue from "vue"
import {TourEvents} from "@uilicious/cake-ui"
import {store} from "@/store"
import analytics from "@uil/analytics/module"

export const tours = {
	gettingStarted,
}

const tourConfigs: TourConfig[] = Object.values(tours)

export function setupTourEventHandlers (eventHub: Vue) {
	eventHub.$on(TourEvents.TOUR_PROGRESS, (event: { tourName: string, step: number }) => {
		if (event.step === 0) {
			analytics.track("Onboarding Tour Started", {
				value: 1,
			})
		} else {
			analytics.track("Onboarding Tour Progress", {
				tour_step: event.step,
				value:     1,
			})
		}
	})

	eventHub.$on(TourEvents.TOUR_ENDED, (event: { tourName: string, skipped: boolean, step: number }) => {
		store.dispatch("accountSettings/markTourAsCompleted", event.tourName)

		if (event.skipped) {
			analytics.track("Onboarding Tour Skipped", {
				tour_step: event.step,
				value:     0,
			})
		} else {
			analytics.track("Onboarding Tour Completed", {
				value: 10,
			})
		}
	})
	// eventHub
}

export default tourConfigs