<template>
	<modal
		:title="title"
		size="small"
	>
		<template #content>
			<div>
				<p v-if="message">
					{{ message }}
				</p>
				<p>
					Choose a plan to activate your account and continue using UI-licious to:
				</p>
				<ul>
					<li>Create projects and tests</li>
					<li>Run tests</li>
					<li>Schedule and run jobs</li>
				</ul>
			</div>
		</template>
		<template #footer>
			<div>
				<cake-button
					@click="close"
				>
					Close
				</cake-button>
				<upgrade-tag
					:space="activeSpace"
					size="md"
				>
					{{ buttonText }}
				</upgrade-tag>
			</div>
		</template>
	</modal>
</template>
<style lang="scss" rel="stylesheet/scss" scoped>

</style>
<script type="text/babel">

//--------------------------------------------------
// Static dependencies
//--------------------------------------------------

//--------------------------------------------------
// Component
//--------------------------------------------------
import {mapGetters} from "vuex"
import UpgradeTag from "@uil/components/subscription/UpgradeTag"

export default {
	//--------------------------------------------------
	// Properties
	//--------------------------------------------------
	props: {
		title: {
			type:    String,
			default: "Upgrade your plan",
		},
		message: {
			type: String,
		},
		buttonText: {
			type:		  String,
			default: "Upgrade your plan",
		}
	},
	computed: {
		...mapGetters("workspace", ["billingUrl", "activeSpace"]),
	},
	//--------------------------------------------------
	// Methods
	//--------------------------------------------------
	methods: {
		//
		// Modal related
		//
		close() {
			this.$emit("close")
		},
	},
	components: {UpgradeTag},
}
</script>
