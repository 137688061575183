

export default {
	props: {
		enabled: {
			type: Boolean,
			default: true
		},
		websiteId: {
			required: true,
			type: String,
		}
	},
	data(){
		return {
			isChatOpen: false,
			sourceUrl: null
		}
	},
	computed: {
		chatEnabled(){
			// disable chat for on-premise installation
			if(this.$onPremise.onPremise){
				return false
			}
			// disable chat for super admins
			if(this.$user.impersonated){
				return false
			}
			return this.enabled // use enable prop set on the crisp-chat component
		},
		crispTokenId(){
			return this.$user._oid;
		}
	},
	watch: {
		"$route": {
			handler(){
				// track page views on crisp as events
				let frame = this.$refs.chatWindow?.contentWindow
				frame?.postMessage({eventName: "page_view", data: {page_url: location.href, page_title: document.title}, color: "blue"})
			},
			deep: true
		}
	},
	beforeCreate() {
		// set window.$crisp
		if(typeof window.$crisp === "undefined"){
			Object.defineProperty(window, "$crisp", {
				get() {
					try {
						// @ts-ignore
						return document.getElementById("chat-window").contentWindow.$crisp
					} catch(e) {
						return null
					}
				},
			});
		}
	},
	created() {
		this.$eventHub.$on("CHAT_SHOW", this.openChat)
		this.$eventHub.$on("beforeLogout", this.logoutChatSession)
	},
	beforeDestroy() {
		this.$eventHub.$off("CHAT_SHOW", this.openChat)
		this.$eventHub.$off("beforeLogout", this.logoutChatSession)
	},
	methods: {
		openChat(){
			this.isChatOpen = true
		},
		closeChat(){
			this.isChatOpen = false
		},
		logoutChatSession(){
			// Log user's Crisp Chat session out
			let frame = this.$refs.chatWindow.contentWindow
			frame.postMessage("logout")
		}
	}
}
