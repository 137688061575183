import Vue from "vue"
import {GetterTree, MutationTree} from "vuex";
import {TestRunConfiguration} from "@/models/TestRunConfiguration";
import {RootState, TestRunConfigurationsState} from "@/store/types";
import api from "@uil/api";


const state: TestRunConfigurationsState = {
	map: {} // map of run configurations by ID
}

const mutations: MutationTree<TestRunConfigurationsState> = {
	/**
	 * Save a test run configuration to store
	 * @param state
	 * @param config
	 */
	saveTestRunConfig(state:TestRunConfigurationsState, config:TestRunConfiguration){
		Vue.set(state.map, config._oid, config);
	},
	/**
	 * Delete a test run configuration from store
	 * @param state
	 * @param id
	 */
	deleteTestRunConfig(state: TestRunConfigurationsState, id: string){
		Vue.delete(state.map, id);
	}
}

const getters: GetterTree<TestRunConfigurationsState, RootState> = {
	/**
	 * Return list of test run configurations for current project
	 * @param state
	 * @param getters
	 * @param rootState
	 */
	configurationsForCurrentProject(state: TestRunConfigurationsState, getters, rootState){

		let projectID = rootState.route.params.projectId
		let defaultTestRunConfigurationID = rootState.accountSettings.projectSettings[projectID]?.defaultTestRunConfiguration ?? ""

		return Object.values(state.map)
				.filter((config: TestRunConfiguration)=>{
					return config.projectID === projectID
				})
				.sort((a, b)=>{
					// default configuration first
					if(a._oid === defaultTestRunConfigurationID){
						return -1
					}
					if(b._oid === defaultTestRunConfigurationID){
						return 1
					}
					// then sort from A to Z
					if(a.name.toLowerCase() < b.name.toLowerCase()){
						return -1
					}
					return 1;
				})
	}
}

const actions = {
	/**
	 * Fetch the test run configuration for the current project
	 */
	async fetchTestRunConfigurations({commit}, projectId) {
		return await api.project.testrun.config.list(projectId)
				.then((data)=>{

					if(!data.result){
						throw new Error("missing 'result' in response")
					}

					// cast from json to TestRunConfiguration instance
					let configs = data.result.map((o)=>{
						return new TestRunConfiguration(o)
					})

					// save to store
					configs.forEach((config)=>{
						commit("saveTestRunConfig", config)
					})

					return configs

				})
	},
	/**
	 * Save test run configuration
	 * @param commit
	 * @param dispatch
	 * @param state
	 * @param config
	 */
	async saveTestRunConfiguration({commit, dispatch, state}, config: TestRunConfiguration){

		// prepare request data
		let reqData:any = {}
		if(config._oid) { reqData._oid = config._oid }
		reqData.name = config.name
		if(config.browser){ reqData.browser = config.browser }
		if(config.width) { reqData.width = config.width }
		if(config.height) { reqData.height = config.height }
		if(config.environmentDataID) { reqData.environmentDataID = config.environmentDataID }
		if(config.region) { reqData.region = config.region }
		if(config.userAgent) { reqData.userAgent = config.userAgent }

		return await api.project.testrun.config.save(config.projectID, reqData)
				.then((data)=>{

					// save result
					if(!data.result){
						throw new Error("missing 'result' in response")
					}

					// commit the new entry to store
					let savedConfig = new TestRunConfiguration(data.result)
					commit("saveTestRunConfig", savedConfig)

					return state.map[savedConfig._oid] // return the copy in the store

				})
	},
	/**
	 * Delete a test run configuration
	 * @param commit
	 * @param config
	 */
	deleteTestRunConfiguration({commit}, config: TestRunConfiguration){

		return api.project.testrun.config.delete(config.projectID, {
			_oid: config._oid
		}).then((data)=>{

			if(!data.result){
				throw new Error("error deleting test run configuration, response from server: " + JSON.stringify(data))
			}

			// delete the configuration from store
			commit("deleteTestRunConfig", config._oid)

		})

	}
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}
