import Vue from "vue"

import VuePermissions from "@/plugins/vue-permissions"
import EventHubPlugin, {eventHub} from "@/plugins/eventHub"
import {
	CakePlugin,
	TourPlugin,
	CakeDirectives,
} from "@uilicious/cake-ui"
import VueOnlineProp from "vue-online-prop"
import PortalVue from "portal-vue"
import Vuelidate from "vuelidate"
import DurationFilter from "@uil/filters/duration"
import DatetimeFilter from "@uil/filters/datetime"
import hhmmzFilter from "@uil/filters/hhmmz"
import RelativeTimeFilter from "@uil/filters/relativeTime"
import * as Store from "@/store"
import permissions from "@/permissions"
import {sync as VuexRouterSync} from "vuex-router-sync"
import router from "@/router"
import {getCookie, setCookie} from "@/utils/cookie"
import * as LocalStorageSync from "@uil/util/LocalStorageSync"
import {default as $user} from "@/user"
import tours from "@/tours"

import "@/global_components"
import "@/global_async_components"
import {Studio} from "@/main"

/**
 * Run the code to setup Vue
 */
export function run (app: Studio): Promise<Studio> {

	// plugins
	Vue.use(PortalVue)
	Vue.use(EventHubPlugin)
	Vue.use(CakePlugin)
	Vue.use(TourPlugin, {
		tours,
		eventHub
	})
	Vue.use(VueOnlineProp)
	Vue.use(Vuelidate)

	// filters
	Vue.use(DurationFilter)
	Vue.use(DatetimeFilter)
	Vue.use(RelativeTimeFilter)
	Vue.use(hhmmzFilter)

	// directives
	Vue.directive("Tooltip", CakeDirectives.Tooltip)

	// mixins
	Vue.mixin({
		beforeCreate () {

			// cookie management methods
			Vue.prototype.$cookie = {
				set: setCookie,
				get: getCookie,
			}

			// EXPOSE USER INFO TO EVERY COMPONENT
			Vue.prototype.$user = $user

		},
		created () {
			// pass the $logout method from root to all vue components
			if (!this.$logout && this.$root.$logout) {
				this.$logout = this.$root.$logout
			}

			// pass the $alertIfInactiveSubscription method from root to all vue components
			if (!this.$alertIfInactiveSubscription &&
				this.$root.$alertIfInactiveSubscription) {
				this.$alertIfInactiveSubscription = this.$root.$alertIfInactiveSubscription
			}
		},
	})

	// global notifications
	Object.defineProperty(Vue.prototype, "$globalNotification", {
		get: function get () {
			return this.$root._globalNotification
		},
	})
	Vue.mixin({
		beforeCreate () {
			if (this.$options.globalNotification) {
				this._globalNotification = this.$options.globalNotification
			}
		},
	})

	//
	// We have some issues with getting LocalStorageSync, which
	// syncs the local storage state to the server, to play nicely with the
	// vuex-persist plugin, because there's some mutation observer used by
	// the plugin that keeps calling the setItem method when the store is initialised.
	//
	// let localStorage = LocalStorageSync.install({
	//     prefix: "uilc_",
	//     userID: app.user._oid,
	//     impersonatingUser: app.user.impersonated
	// })
	// app.localStorage = localStorage
	//
	// Vue.mixin({
	//     beforeCreate(){
	//         Vue.prototype.$localStorage = window.localStorage
	//     }
	// })

	// vuex store
	app.store = Store.install({
		storage: window.localStorage
		//storage: localStorage
	})

	// sync store and router
	VuexRouterSync(app.store, router)

	// uilicious permissions
	VuePermissions.sync(app.store, permissions)
	Vue.use(VuePermissions)

	return Promise.resolve(app)

}
