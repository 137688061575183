//--------------------------------------------------
// Static dependencies
//--------------------------------------------------
import Vue from "vue"
import api from "@uil/api"
import {DatasetsState, RootState} from "@/store/types"
import {ActionTree, GetterTree, Module, MutationTree} from "vuex"

//--------------------------------------------------
// State
//--------------------------------------------------
const state: DatasetsState = {
	map: {}, // map of dataset
}

//--------------------------------------------------
// Mutations
//--------------------------------------------------
const mutations: MutationTree<DatasetsState> = {
	addDatasets(state, datasets) {
		if (Array.isArray(datasets)) {
			datasets.forEach(dataset => {
				Vue.set(state.map, dataset._oid, dataset)
			})
		}
	},
	deleteDataset(state, id) {
		if (id) {
			Vue.delete(state.map, id)
		}
	},
}

//--------------------------------------------------
// Getters
//--------------------------------------------------
const getters: GetterTree<DatasetsState, RootState> = {
	datasetsForCurrentProject(state, getters, rootState) {
		const projectId = rootState.route.params.projectId
		const allDatasets = Object.values(state.map)
		return allDatasets.filter((dataset) => {
			return dataset.projectId === projectId
		})
	}
}

//--------------------------------------------------
// Actions
//--------------------------------------------------
const actions: ActionTree<DatasetsState, RootState> = {
	fetchDatasets({commit}, projectId) {
		return api.project.dataset
			.list({
				projectId: projectId,
			})
			.then(data => {

				if (typeof data.result === "undefined" || data.result == null) {
					throw new Error("missing `result` from response")
				}

				const datasets = data.result
				commit("addDatasets", datasets)

				return datasets
			})
	},
	deleteDataset({commit}, {id, projectId}) {
		return api.project.dataset
			.delete({
				id:        id,
				projectId: projectId,
			})
			.then(() => {

				// commit the change
				commit("deleteDataset", id)

			})
	}
}

const DatasetsStore: Module<DatasetsState, RootState> = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}

export default DatasetsStore
