import Analytics from "analytics"
import segmentPlugin from "@analytics/segment"
import {User} from "@uil/models/User"

let installed = false

const analytics = Analytics({
	app:     "uilicious",
	plugins: [
		segmentPlugin({
			writeKey: __SEGMENT_TRACKING_ID__,
			enabled:  false,
		}),
	],
})

export const install = async (user?: User) => {
	if (installed) {
		return
	}

	const enabledPlugins = [
		__SEGMENT_TRACKING_ENABLED__ && "segment",
	].filter(Boolean)

	if (enabledPlugins.length > 0) {
		await analytics.plugins.enable(enabledPlugins)

		analytics.page()

		if (user && !user.impersonated) {
			analytics.identify(user.__oid)
		}
	}

	installed = true
}

export default analytics
