<template>
	<cake-button
		class="brand upgrade-plan-tag"
		:size="size"
		@pointerdown="onClick"
	>
		<i class="fa fa-flash" />
		<slot>Upgrade your plan</slot>
	</cake-button>
</template>
<script>
import ContactOwnerToUpgradeModal from "./ContactOwnerToUpgradeModal"
import {CakeButton} from "@uilicious/cake-ui"

export default {
	props: {
		space: {
			type: Object,
		},
		size: {
			type:    String,
			default: "xs",
		},
	},
	computed: {
		shouldContactOwner() {
			return this.space != null && this.space._userRole !== "owner"
		},
		billingUrl() {
			let billingUrl = __BILLING_URL__ ?? ""

			if (this.space != null) {
				billingUrl += `/space?id=${this.space._oid}`
			}

			return billingUrl
		},
	},
	methods: {
		onClick(e) {
			if (this.shouldContactOwner) {
				this.$modal.open(ContactOwnerToUpgradeModal)
			} else {
				window.open(this.billingUrl, "_blank")
			}
		},
	},
	components: {
		CakeButton,
	},
}
</script>
<style lang="scss">
.upgrade-plan-tag {
	pointer-events: auto;

	&.cake-btn--xs {
		height    : 1.125rem;
		font-size : .75em;
		padding   : 0 .375rem;
		gap       : .25rem;
	}
}
</style>