//--------------------------------------------------
// Static dependencies
//--------------------------------------------------
import Project from "@uil/models/Project"
import api from "@uil/api"
import * as Intercom from "@uil/analytics/intercom"
import {default as permissions, PROJECT_PERMISSIONS_FOR_ROLE} from "@/permissions"

//--------------------------------------------------
// State
//--------------------------------------------------
const state = {
	initialising: false /* deprecate because we use router-guard now */,
	map:          {
	}
}

//--------------------------------------------------
// Mutations
//--------------------------------------------------
const mutations = {
	setProjects(state, projects) {
		//clear all tests
		_.forEach(state.map, (v, k) => {
			Vue.delete(state.map, k)
		})

		//set tests
		_.forEach(projects, (project) => {
			Vue.set(state.map, project.id, project)
		})
	},
	setProject(state, _project) {
		if (!_.isNil(_project)) {
			let project = state.map[_project.id]
			if (project) {
				Object.assign(project, _project)
			} else {
				Vue.set(state.map, _project.id, _project)
			}
		}
	},
	deleteProject(state, projectId) {
		if (!_.isNil(projectId)) {
			Vue.delete(state.map, projectId)
		}
	}
}

//--------------------------------------------------
// Getters
//--------------------------------------------------
const getters = {
	projectList(state) {
		return _.values(state.map)
	},
	projectListOrderedByName(state, getters) {
		let projects = getters.projectList
		return _.sortBy(projects, ["title"])
	},
	projectsGroupedBySpace(state, getters, rootState) {
		let projectsBySpace = {
		}

		// get the space IDs from rootState
		const spaceIdsFromRootState = Object.keys(rootState.spaces.map)
		// initialise empty array to each space
		spaceIdsFromRootState.forEach(spaceID => {
			projectsBySpace[spaceID] = []
		})

		// group by space
		let projectIds = Object.keys(state.map)
		projectIds.forEach((projectId) => {
			let project = state.map[projectId]
			projectsBySpace[project.spaceID] = projectsBySpace[project.spaceID] || []
			projectsBySpace[project.spaceID].push(project)
		})

		// sort projects alphabetically
		let spaceIDs = Object.keys(projectsBySpace)
		spaceIDs.forEach((spaceID) => {
			projectsBySpace[spaceID] = _.sortBy(projectsBySpace[spaceID], "name")
		})

		return projectsBySpace
	}
}

//--------------------------------------------------
// Actions
//--------------------------------------------------
const actions = {
	setSpaceProjects({commit}, {space, projects}){

		projects.forEach((project)=>{

			// Cast to Project object
			project = new Project(project)

			// Set project's reference to it's parent space
			if (space) {
				project.space = space
			}

			// Set project permissions
			let projectPermissions = (PROJECT_PERMISSIONS_FOR_ROLE[project.userRole] || [])
			permissions.setPermissions(projectPermissions).on({project: project._oid})

			// Commit to store
			commit("setProject", project)

		})

		// return promise, because conventions
		return Promise.resolve()

	},
	/**
	 * Fetch projects for a space
	 * @param {*} param0
	 * @param {*} space
	 */
	fetchProjects({commit, state, dispatch}, space){
		let request

		if (space._oid !== "") {
			request = api.space.project.list(space._oid, {
				fieldList: ["_oid", "name", "spaceID"],
				start:     0,
				length:    99999
			})
		} else {
			request = api.project.list({
				fieldList: ["_oid", "name", "spaceID"],
				start:     0,
				length:    99999
			})
		}

		return request
			.then(data => {

				if (_.isNil(data.result)) {
					// error retrieving projects , alert error
					console.error("Error retrieving projects, ", data)
					throw new Error("missing `result` from response")
				}

				let projects = data.result

				dispatch("setSpaceProjects", {space, projects})

				return projects

			})
			// let caller handle any exceptions
	},
	addProject({commit, state, rootState}, {name, space}){

		space = space || {_oid: ""} /* fallback to legacy with "" _oid */

		// select either legacy or spaces API to create the project
		let request
		if (space._oid === "") {
			// legacy
			request = api.project.new({
				name: name
			})
		} else {
			// with spaces
			request = api.space.project.create(space._oid, {
				name: name
			})
		}

		return request
			.then(data => {

				// HANDLE ERRORS IF ANY
				if (_.isNil(data.result)) {
					throw new Error("missing `result` from response")
				}

				// There's no errors, proceed.
				let projectId = data.result

				// Create the project object
				let createdProject = new Project({
					_oid:     projectId,
					name:     name,
					space:    space,
					userRole: "owner"
				})

				// Add the project to the store
				commit("setProject", createdProject)

				// Track event
				Intercom.trackEvent("Created Project")

				// set permissions to the project for this user
				let projectPermissions = (PROJECT_PERMISSIONS_FOR_ROLE[createdProject.userRole] || [])
				permissions
					.setPermissions(projectPermissions)
					.on({project: createdProject._oid})

				return createdProject
			})

	}
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}
