import $api from "@uil/api"
import getQueryParameterByName from "@/utils/getQueryParameterByName"
import {default as $user, User} from "@/user"

export function run (app: any): Promise<any> {

	// loginUser (the current identity) may be different from the User (the account to load or impersonate if loginUser is admin)
	let loginUser

	return $api.account.login().then((res) => {
		// Not logged in, redirect to login page
		if (!res.result) {
			throw new Error("User is not logged in")
		}

		// Set the login user
		const {accountID, isSuperUser, loginNameList} = res
		loginUser = {accountID, loginNameList, isSuperUser}

		// Impersonate if super user
		let asAccountID = null
		if (loginUser.isSuperUser) {
			console.log("You are logged in as an administrator.")
			asAccountID = getQueryParameterByName("impersonate")
		}

		const req: any = {}
		if (loginUser.isSuperUser && asAccountID !== null) {
			req.asAccountID = asAccountID
		}

		// Get user information
		return $api.account.info.get(req)

	})
		// set user instance
		.then((res) => {

			const {_oid, loginNameList, name, isSuperUser, emailVerified, profilePictureURL} = res.result
			let {email} = res.result
			const accountID = _oid
			email = email || loginNameList[0]
			$user.set({_oid, accountID, loginNameList, email, name, isSuperUser, emailVerified, profilePictureURL})

			// Impersonating user?
			$user.impersonated = loginUser.isSuperUser && loginUser.accountID !== $user.accountID
			if ($user.impersonated) {
				console.log("Currently impersonating: " + $user.email)
			}
			console.info("Logged In As: %c" + $user.email + ($user.impersonated ? " (IMPERSONATED)" : ""),
				"background: black; color: #18ffff")

			// Modify API endpoints for user impersonation
			if ($user.impersonated) {
				// setupAPIWithImpersonation($api, User._oid)
				$api._core.asAccountID($user._oid)
			}

			app.user = $user
			return app

		}).catch((err) => {

			console.error("Error logging in, redirecting to login page", err)
			window.STUDIO.redirectToLoginPage(true)
			throw err

		})

}
