import sortBy from "lodash/sortBy"
import * as stream from "stream"

export const NODE_TYPE = {
	DIRECTORY: "directory",
	FILE:      "file",
}

class DirectoryNode {
	constructor({
		type = NODE_TYPE.FILE,
		name = "",
		parent = null,
		parentPath = "",
		children = [],
		depth = 0,
		upload = null,
		expanded = false,
		metadata = {}
	}) {

		this._type = type
		this._name = name
		this._parent = parent
		this._parentPath = parentPath
		this._children = []
		this._depth = depth
		this._upload = upload
		this._expanded = expanded
		this._metadata = Object.assign({}, metadata)

		children.forEach((child) => {
			child.parent = this
			child.parentPath = this.path
			child.depth = this.depth + 1
			this._children.push(new DirectoryNode(child))
		})
	}

	//--------------------------------------------------
	// Accessor methods
	//--------------------------------------------------

	get type() {
		return this._type
	}

	get parent() {
		return this._parent
	}

	get parentPath() {
		return this._parentPath
	}

	get name() {
		return this._name
	}

	set name(newName) {
		this._name = newName
	}

	get depth() {
		return this._depth
	}

	get isRoot() {
		return this._depth === 0
	}

	get metadata(){
		return this._metadata;
	}

	set metadata(metadata){
		this._metadata = metadata
	}

	//--------------------------------------------
	// Type checking methods
	//--------------------------------------------

	get isFolder() {
		return this._type === "folder" || this._type === "directory"
	}

	get isFile() {
		return this._type === "file"
	}

	get isTest() {
		return this.isFile && this._name.endsWith(".test.js")
	}

	get isJavascript() {
		return this.isFile && this._name.endsWith(".js")
	}

	//--------------------------------------------------
	// Computed variables
	//--------------------------------------------------

	/**
	 *
	 * @deprecated
	 * @readonly
	 * @memberof DirectoryNode
	 */
	get id() {
		console.warn("`.id` is deprecated - use `.path` instead.")
		return this.path
	}

	get nameTrimmed() {
		return _.trim(this._name, "/")
	}

	get displayName() {
		if (this.isTest) {
			return this._name.substring(0, this._name.length - 8)
		}
		return this._name
	}

	get displayNameTrimmed() {
		return _.trim(this.displayName, "/")
	}

	/**
	 * Get the full path from project root
	 *
	 * @readonly
	 * @memberof DirectoryNode
	 */
	get path() {
		let path = ""
		if (!_.isNil(this._parentPath) && this._parentPath !== "") {
			path += this._parentPath + "/"
		}
		path += this._name
		return path
	}

	get displayPath() {
		if (this.isTest) {
			return this.path.substring(0, this.path.length - 8)
		}
		return this.path
	}

	get displayPathTrimmed() {
		return _.trim(this.displayPath, "/")
	}

	get pathWithoutStartingSlash() {
		if (this.path.startsWith("/")) {
			return this.path.substr(1)
		}
		return this.path
	}

	/**
	 * If this is a file, return the file name extension, including the dot.
	 *
	 * @readonly
	 * @memberof DirectoryNode
	 */
	get extension() {
		if (!this.isFile) {
			return null
		}

		if (this.name.lastIndexOf(".") !== -1) {
			let dot = this.name.lastIndexOf(".")
			return this.name.substring(dot).trim().toLowerCase() // includes the '.' character
		}
		return ""
	}

	//--------------------------------------------------
	// Children
	//--------------------------------------------------
	get children() {
		return this._children || []
	}

	set children(children) {
		this._children = children
	}

	/**
	 * Sorts the children (folders first, then alphabetically)
	 *
	 * @readonly
	 * @memberof DirectoryNode
	 */
	get childrenSorted() {
		// sort the children alphabetically, and place folders before files
		let childrenByAlphabeticalSorting = _.sortBy(this.children, ["name"])
		let folders = _.filter(childrenByAlphabeticalSorting, (child) => {
			return child.isFolder
		})
		let notFolders = _.filter(childrenByAlphabeticalSorting, (child) => {
			return !child.isFolder
		})
		return [].concat(folders, notFolders)
	}

	//--------------------------------------------------
	// Upload methods
	//--------------------------------------------------
	get upload() {
		return this._upload
	}

	set upload(upload) {
		this._upload = upload
	}

	get isUploading() {
		return this._upload !== null && ["queued", "uploading"].includes(this._upload.status)
	}

	revert() {
		if (this.upload == null || this.parent == null) {
			return
		}

		if (!this.upload.overwrite) {
			// Remove the node from the tree
			const index = this.parent.children.indexOf(this)
			this.parent.children.splice(index, 1)
		}

		this.upload = null

		this.parent.revert()
	}

	//--------------------------------------------------
	// Expand/collapse methods
	//--------------------------------------------------
	get expanded() {
		return this._expanded
	}

	set expanded(expanded) {
		this._expanded = expanded
	}

	expandAncestors() {
		let node = this.parent
		while (node != null) {
			node.expanded = true
			node = node.parent
		}
	}
}

export default DirectoryNode
