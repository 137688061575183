<template>
	<modal
		title="Upgrade plan"
		confirm-btn-text="Close"
		:show-close-btn="false"
		size="md"
		@confirm="close"
	>
		<template #content>
			<p>Looks like you don't have permission to change the subscription plan for this workspace.</p>
			<p>Contact the owner of your space to upgrade your plan.</p>
		</template>
	</modal>
</template>

<script>

export default {
	name:    "ContactOwnerToUpgradeModal",
	methods: {
		close() {
			this.$emit("close")
		},
	},
}
</script>
