import axios from "axios"
import {ApiError} from "@uil/api/ApiError"
import {ITestRunIssue, ITestRunIssueType, NewTestRunIssue} from "@uil/models/types"

let BASE_URL = __UILICIOUS_API_BASEURL__
let AS_ACCOUNT_ID = null

const api = {
	admin: undefined,
	_core: {
		baseURL: (url?: string) => {
			if (typeof url == "string" && url !== "") {
				BASE_URL = url
			}
			return BASE_URL
		},
		asAccountID: (accountID) => {
			if (typeof accountID == "string" && accountID !== "") {
				AS_ACCOUNT_ID = accountID
			}
			return AS_ACCOUNT_ID
		},
		callEndpoint: (path, data) => {
			return POST(path, data)
		},
	},
	//-----------------------------------------------------
	// CONFIG
	//-----------------------------------------------------
	config: {
		premise: () => GET("/config/premise"),
	},
	//-----------------------------------------------------
	// NOTIFICATIONS
	//-----------------------------------------------------
	notification: {
		list:       (params?: { includeRead: boolean }) => GET("/notification/list", params),
		markAsRead: (id: string) => POST("/notification/markAsRead", {id}),
	},
	//-----------------------------------------------------
	// BILLING
	//-----------------------------------------------------
	billing: {
		subscription: {
			plan: {
				list: () => GET("/billing/subscription/plan/list"),
				get:  (data) => GET("/billing/subscription/plan/get", data),
			},
		},
	},
	//-----------------------------------------------------
	// ACCOUNT
	//-----------------------------------------------------
	account: {
		/**
		 * Login
		 */
		login:  (data?: Record<string, any>) => POST("/account/login", data),
		/**
		 * Logout
		 */
		logout: () => POST("/account/logout"),
		/**
		 * Get information about a user (defaults to the currently logged in user)
		 */
		info:   {
			get: (data) => GET("/account/info/get", data),
		},
		settings: {
			get: (data?: Record<string, any>) => GET("/account/settings/get", data),
			set: (data) => POST("/account/settings/set", data),
		},
		resendVerificationEmail: () => POST("/account/resendVerificationEmail"),
	},
	//-----------------------------------------------------
	// ACCESS KEY
	//-----------------------------------------------------
	accessKey: {
		get: (config = {}) => GET("/accesskey/get", {}, config),
	},
	//-----------------------------------------------------
	// SPACE
	//-----------------------------------------------------
	space: {
		/**
		 * List spaces that the user belongs to
		 */
		list:           () => POST("/space/list"),
		/**
		 * Create a space
		 */
		create:         (data) => POST("/space/create", data),
		/**
		 * Get information about a space
		 */
		info:           (spaceID) => GET(`/space/${spaceID}/info`),
		/**
		 * Rename a space
		 */
		rename:         (spaceID, data) => POST(`/space/${spaceID}/rename`, data),
		/**
		 * Update space settings
		 */
		updateSettings: (spaceID, data) => POST(`/space/${spaceID}/settings`, data),
		/**
		 * Delete a space
		 */
		delete:         (spaceID) => POST(`/space/${spaceID}/delete`),
		project:        {
			/**
			 * List projects in a space
			 */
			list:   (spaceID, data) => POST(`/space/${spaceID}/project/list`, data),
			/**
			 * Create a project
			 */
			create: (spaceID, data) => POST(`/space/${spaceID}/project/create`, data),
			/**
			 * Update a project
			 */
			update: (spaceID: string, projectId: string, data: FormData) => POST(
				`/space/${spaceID}/project/${projectId}/update`, data),
			/**
			 * Delete a project
			 */
			delete: (spaceID, projectId) => POST(`/space/${spaceID}/project/${projectId}/delete`),
		},
		member: {
			/**
			 * List members and invitations in a space
			 */
			list:       (spaceID) => POST(`/space/${spaceID}/member/list`),
			/**
			 * Delete a member from a space
			 */
			delete:     (spaceID, data) => POST(`/space/${spaceID}/member/delete`, data),
			edit:       (spaceID, data) => POST(`/space/${spaceID}/member/edit`, data),
			invitation: {
				/**
				 * List invitations for this space
				 */
				list:   (spaceID) => POST(`/space/${spaceID}/member/invitation/list`),
				/**
				 * Create an invitation to invite a user to join a space
				 */
				create: (spaceID, data) => POST(`/space/${spaceID}/member/invitation/create`, data),
				/**
				 * Resend an invitation
				 */
				resend: (spaceID, data) => POST(`/space/${spaceID}/member/invitation/resend`, data),
				/**
				 * Delete (/Withdraw) an initation
				 */
				delete: (spaceID, invitationId) => POST(`/space/${spaceID}/member/invitation/${invitationId}/delete`),
			},
		},
		invitation: {
			/**
			 * (Public API) Get information about an invitation
			 */
			info:   (invitationId) => GET(`/space/invitation/${invitationId}/info`),
			/**
			 * Accept an invitation
			 */
			accept: (invitationId) => POST(`/space/invitation/${invitationId}/accept`),
			/**
			 * List all invitation
			 */
			list:   () => GET("/space/invitation/list"),
		},
	},
	//-----------------------------------------------------
	// PROJECT (legacy)
	//-----------------------------------------------------
	project: {
		/**
		 * List all projects
		 * @deprecated For legacy api with
		 */
		list:   (data) => POST("/project/list", data),
		/**
		 * Get info about a project
		 * @param data
		 * @returns {*}
		 */
		get:    (data) => GET("/project/get", data),
		/**
		 * Create a new project
		 * @deprecated
		 */
		new:    (data) => POST("/project/new", data),
		/**
		 * Update a project
		 * @param data
		 * @returns {*}
		 * @deprecated
		 */
		update: (data) => POST("/project/update", data),
		/**
		 * Delete a project
		 * @param projectID
		 * @returns {*}
		 * @deprecated
		 */
		delete: (projectID) => POST("/project/delete", {projectID: projectID}),
		/**
		 * CRUD APIs for project files
		 */
		file:   {
			query:  (data) => POST("/project/file/query", data),
			// For /file/get - use POST method instead of GET
			// - this is due to some issues encoding square brackets in the filepath properly when using the GET method.
			// - unfortunately, the axios community cannot make up their mind on whether or not square brackets
			//   should be encoded, see https://github.com/axios/axios/issues/3316.
			get:       (data, config = {}) => POST("/project/file/get", data, config),
			put:       (data, config = {}) => POST("/project/file/put", data, config),
			move:      (data) => POST("/project/file/move", data),
			copy:      (data) => POST("/project/file/move", {...data, copy: true}),
			protect:   (data) => POST("/project/file/protect", data),
			unprotect: (data) => POST("/project/file/unprotect", data),
			delete:    (data) => POST("/project/file/delete", data),
		},
		/**
		 * CRUD APIs for project test runs
		 */
		testrun: {
			list: {
				running: (data) => POST("/project/testrun/list/running", data),
				history: (data, config = {}) => POST("/project/testrun/list/history", data, config),
			},
			start: (data) => POST("/project/testrun/start", data),
			get:   (data) => GET("/project/testrun/get", data),
			stop:  (testRunID) => POST(`/project/testrun/${testRunID}/stop`, testRunID),
			issue: {
				list:    (testRunID: string) => GET(`/project/testrun/${testRunID}/issue/list`),
				create:  (testRunID: string, data: NewTestRunIssue) => POST(`/project/testrun/${testRunID}/issue/create`, data),
				update:  (issueID: string, data: Partial<ITestRunIssue>) => POST(`/project/issue/${issueID}/update`, data),
				delete:  (issueID: string) => POST(`/project/issue/${issueID}/delete`),
				comment: {
					create: (issueID: string, message: string) => POST(`/project/issue/${issueID}/comments/create`, {message}),
					update: (commentID: string, message: string) => POST(`/project/issue-comment/${commentID}/update`,
						{message}),
					delete: (commentID: string) => POST(`/project/issue-comment/${commentID}/delete`),
				},
			},
			config: {
				list: (projectID) => POST(`/project/${projectID}/testrun/config/list`),
				save: (projectID, data) => POST(`/project/${projectID}/testrun/config/save`, data),
				delete: (projectID, data) => POST(`/project/${projectID}/testrun/config/delete`, data)
			}
		},
		testrunbill: {
			list: (projectID, data) => GET(`/project/${projectID}/testrunbill/list`, data),
			get: (projectID, billID) => GET(`/project/${projectID}/testrunbill/${billID}`),
			stop: (projectID, billID) => POST(`/project/${projectID}/testrunbill/${billID}/stop`)
		},
		/**
		 * CRUD APIS for project datasets
		 */
		dataset: {
			list:   (data) => GET("/project/environment/list", data),
			create: (data) => POST("/project/environment/create", data),
			update: (data) => POST("/project/environment/update", data),
			clone:  (data) => POST("/project/environment/clone", data),
			delete: (data) => POST("/project/environment/delete", data),
		},
		/**
		 * CRUD APIS for project datasets
		 */
		job: {
			// quota
			quota:      (projectID) => POST("/project/job/quota", {projectID: projectID}),
			add:        (projectID, data) => POST(`/project/${projectID}/job/add`, data),
			list:       (data) => POST("/project/job/list", data),
			get:        (jobID) => GET(`/project/job/${jobID}`),
			update:     (projectID, jobID, data) => POST(`/project/${projectID}/job/${jobID}/update`, data),
			delete:     (jobID) => POST(`/project/job/${jobID}/delete`),
			disable:    (jobID) => POST(`/project/job/${jobID}/disable`),
			enable:     (jobID) => POST(`/project/job/${jobID}/enable`),
			run:        (jobID) => POST(`/project/job/${jobID}/run`),
			testrunset: {
				list: (data) => GET("/project/job/testrunset/list", data),
			},
		},
		/**
		 * CRUD APIS for project ui filters
		 */
		uiFilters: {
			list: (projectId, data) => GET(`project/${projectId}/ui-filters/list`, data),
			add: (projectId, data) => POST(`project/${projectId}/ui-filters/add`, data),
			update: (projectId, filterId, data) => POST(`project/${projectId}/ui-filters/${filterId}/update`, data),
			delete: (projectId, filterId, data) => POST(`project/${projectId}/ui-filters/${filterId}/delete`, data)
		}
	},
	//-----------------------------------------------------
	// WEB-STORAGE
	//-----------------------------------------------------
	webLocalStorage: {
		get: () => GET("/web-local-storage/get"),
		put: (data) => POST("/web-local-storage/put", data),
	},
}

export default api

export type API = typeof api;

function errorHandler (err) {

	try {

		/**
		 * we want Promise.reject to return an error that looks like:
		 * {
		 *     // from uilicious-api
		 *     code,
		 *     message,
		 *     stack,
		 *     // from Axios
		 *     response:
		 *     {
		 *         data, // <- this should have the ERROR object, which as {code, message, stack}
		 *         status,
		 *         statusText,
		 *         headers
		 *     },
		 * }
		 */

		const errorInfo = err.response.data.ERROR

		const newError = new ApiError(errorInfo.message || err.message)
		newError.isApiError = true
		newError.code = errorInfo.code
		newError.stack = errorInfo.stack
		newError.request = err.request // put the original XMLHttpRequest
		newError.response = {
			data:       err.response.data,
			status:     err.response.status,
			statusText: err.response.statusText,
			headers:    err.response.headers,
		}
		return Promise.reject(newError)

	} catch (e) {
		/* ignore */
		return Promise.reject(err)
	}

}

function GET (url, params = undefined, config = {}) {
	// todo: handle user being online or offline
	return axios.get(BASE_URL + url, {...config, params: transformParams(params), withCredentials: true}).then(
		(res) => {
			if (typeof res.data.ERROR !== "undefined" && res.data.ERROR !== null) {
				const error = res.data.ERROR
				error.response = res
				return Promise.reject(error) /* {code, message, stack} */
			}
			// todo: this is missing from the /config/premise method
			// if(typeof res.data.result === "undefined"){
			// 	return Promise.reject("missing `result` from response")
			// }
			return Promise.resolve(res.data)
		}).catch((e) => {
		throw e
	}).catch(errorHandler)
}

function POST (url, data = undefined, config = {}) {
	// todo: handle user being online or offline
	return axios.post(BASE_URL + url, transformRequestData(data), {...config, withCredentials: true}).then((res) => {
		if (typeof res.data.ERROR !== "undefined" && res.data.ERROR !== null) {
			const error = res.data.ERROR
			error.response = res
			return Promise.reject(error) /* {code, message, stack} */
		}
		// todo: this is missing from the /config/premise method
		// if(typeof res.data.result === "undefined"){
		// 	return Promise.reject("missing `result` from response")
		// }
		return Promise.resolve(res.data)
	}).catch(errorHandler)
}

function transformParams (data) {
	if (typeof data === "undefined" || null) {
		data = {}
	}
	// Impersonation
	if (AS_ACCOUNT_ID) {
		data.asAccountID = AS_ACCOUNT_ID
	}
	return data
}

function transformRequestData (data: Record<string, any> | FormData | undefined) {
	if (typeof data === "undefined" || null) {
		data = new FormData()
	}
	// coerce into form data
	let formData = new FormData()
	if (data instanceof FormData) {
		formData = data
	} else if (typeof data === "object") {
		Object.keys(data).forEach((prop) => {
			const value = data[prop]
			if (value !== undefined) {
				if (Array.isArray(value) || value instanceof Object) {
					formData.append(prop, JSON.stringify(value))
				} else {
					formData.append(prop, value)
				}
			}
		})
	}
	// Impersonation
	if (AS_ACCOUNT_ID) {
		formData.append("asAccountID", AS_ACCOUNT_ID)
	}
	return formData
}
