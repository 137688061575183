export interface TestRunConfigurationInterface {

	filePath?: string
	browser?: string
	resolution?: string
	environmentDataId?: string
	region?: string
	userAgent?: string

}

export class TestRunConfiguration implements TestRunConfigurationInterface {

	// saved test run configuration information
	_oid?: string // configuration ID
	projectID?: string // ID of the project that this config belongs to
	name?: string // name of the configuration
	default: boolean // is the configuration the default of the project

	// test run configuration (extended)
	browser?: string // browser to run the test with
	width?: number // resolution width
	height?: number // resolution height
	private _dataset: string // dataset to run the test with
	region?: string // region to run the test with
	userAgent?: string // user agent to run the test with

	// logs
	createdBy?: string // ID of the user who created the configuration
	createdAt?: number // creation timestamp in epoch seconds
	updatedBy?: string // ID of the user who last updated the configuration
	updatedAt?: number // last update timestamp in epoch seconds

	constructor(o?) {
		// copy all props
		if(o && typeof o === "object"){
			Object.assign(this, o)
		}
	}

	/**
	 * Get test run resolution
	 */
	get resolution(): string{
		if(this.width && this.height){
			return `${this.width}x${this.height}`
		}
		return ""
	}

	/**
	 * Set test run resolution
	 * @param value
	 */
	set resolution(value: string){
		let tokens = value.split("x")
		this.width = Number.parseInt(tokens[0].trim())
		this.height = Number.parseInt(tokens[1].trim())
	}


	/**
	 * Get dataset to use for the test run
	 */
	get dataset(){
		return this._dataset
	}

	/**
	 * Set the dataset to use for the test run
	 * @param value
	 */
	set dataset(value){
		this._dataset = value
	}

	/**
	 * Get dataset to use for the test run
	 */
	get environmentDataID(){
		return this._dataset
	}

	/**
	 * Set the dataset to use for the test run
	 * @param value
	 */
	set environmentDataID(value){
		this._dataset = value
	}

	/**
	 * Get dataset to use for the test run
	 */
	get environmentDataId(){
		return this._dataset
	}

	/**
	 * Set the dataset to use for the test run
	 * @param value
	 */
	set environmentDataId(value){
		this._dataset = value
	}

	/**
	 * Is this configuration the default for this project?
	 */
	get isDefault(): boolean {
		return !!this.default;
	}

}
