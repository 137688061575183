import moment from "moment"

let installed = false

function install(Vue){
	
	if(installed){
		return
	}

	Vue.filter("relativeTime", function(value /* expect epoch milliseconds or Date */){
		if(typeof value === "number"){
			value = new Date(value)
		}
		if(value instanceof Date){
			return moment(value).fromNow()
		} 
		return value
	})
	
	installed = true

}

export default {
	install
}