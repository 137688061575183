/**
 * Print error on screen - only do this for fatal errors
 */
export function printErrorOnScreen(error) {
    try {
        if (!error) {
            return
        }
        let errorMsg = error.message || error
        let errorLogEl = document.getElementById("error-log")
        if (errorLogEl.innerHTML.trim() !== "") { // add new line if there's existing text
            errorLogEl.innerHTML += "<br>"
        }
        errorLogEl.innerHTML += "Error: " + errorMsg // print error
        errorLogEl.style.display = "block" // show error log
    } catch (e) {
        console.error(error)
    }
}
