//--------------------------------------------------
// Static dependencies
//--------------------------------------------------
import Vue from "vue"
import {EDITOR_STATUS} from "enum"
import {MutationTree} from "vuex"
import {EditorState, Side} from "@/store/types"
import {TestRunConfiguration} from "@/models/TestRunConfiguration";

interface SetActiveTabPayload<T extends Side> {
	side: T,
	tabId: EditorState["activeTabs"][T],
}

const state: EditorState = {
	// status:       EDITOR_STATUS.LOADING, // current status of the editor
	isFocused:    false,
	// modified:     false, // whether or not the contents has been modified (to trigger autosave or warn users about discarding unsaved changes)
	// lastModified: null, // time when contents are last modified
	activeTabs:   {
		left:  "workspace",
		right: "results",
	},
	previousTabs: {
		left:  "workspace",
		right: "results",
	},
	// configuration to run the test with from the editor
	// - we want to keep this synchronised across different components
	useSavedConfiguration: "", // ID of the saved configuration
	testRunConfiguration: {
		browser: "chrome",
		resolution : "1280x960",
		dataset: "",
		region: "",
		userAgent: ""
	}
}

// // Get the value of `autosave` from localstorage (fast local cached copy)
// // - We will also get from the server (slow remote storage) the value of `autosave` once the app is initialised in `setupStudio.ts`
// if(window.localStorage.getItem("autosave") != null){
// 	state.autosave = window.localStorage.getItem("autosave")
// }

//-------------------------------
// MUTATIONS
//-------------------------------

const mutations: MutationTree<EditorState> = {
	// setStatus (state, status) {
	// 	if (status != null) {
	// 		state.status = status
	// 	}
	// },
	setIsFocused (state, isFocused: boolean) {
		state.isFocused = isFocused
	},
	// setModified (state, modified) {
	// 	state.modified = modified
	// },
	// setLastModified (state, lastModified) {
	// 	state.lastModified = lastModified
	// },
	setActiveTab<T extends Side = Side> (state, payload: SetActiveTabPayload<T>) {
		const {side, tabId} = payload

		state.previousTabs[side] = state.activeTabs[side]
		state.activeTabs[side] = tabId
	},
	/**
	 * Update test run configuration
	 * @param state
	 * @param payload
	 */
	updateTestRunConfiguration(state: EditorState, payload){

		if(payload instanceof TestRunConfiguration && payload._oid){

			// apply test run configuration
			// - this will update all settings, and set null-ish values to empty string

			state.useSavedConfiguration = payload._oid
			Object.keys(state.testRunConfiguration).forEach((key)=>{
				let value = payload[key]
				if(!value){ value = "" } // set null-ish values to empty strings
				Vue.set(state.testRunConfiguration, key, value)
			})

		} else {

			// only apply changes to settings included in the payload

			let modified = false
			Object.keys(state.testRunConfiguration).forEach((key)=>{
				if(typeof payload[key] !== "undefined"){
					// update only when the values have changed, to prevent infinite loop when synchronising values with components
					if(state.testRunConfiguration[key] !== payload[key]){
						Vue.set(state.testRunConfiguration, key, payload[key])
						modified = true
					}
				}
			})

			if(modified){
				state.useSavedConfiguration = null
			}

		}

	}
}

const actions = {}

//-------------------------------
// STORE
//-------------------------------

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
