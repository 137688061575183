import { TestRunConfigurationInterface } from "@/models/TestRunConfiguration"
import TestRunSet from '@/models/TestRunSet';
import TestRunBill from '@/models/TestRunBill';

export interface TestRunConfigurationGenerator {
	type: string
	default?: TestRunConfigurationInterface
	matrix?: {
		filePaths?: string[]
		browsers?: string[]
		resolutions?: string[]
		environmentDataId?: string
		region?: string
		userAgent?: string
	},
	matrixList?: TestRunConfigurationInterface[]
	additiveList?: TestRunConfigurationInterface[]
}

export default class Job {

	_oid?: string
	projectID?: string

	name?: string

	// blueprint
	blueprint?: TestRunConfigurationGenerator[]
	_filePath?: string = null
	filePaths?: string[] = []
	environmentDataId: string
	browsers?: string[] = []
	resolutions?: string[] = []
	region?: string = null
	userAgent?: string = null

	// schedule
	scheduleDays?: number[]
	scheduleDaysOfMonth?: number[]
	scheduleDate?: string
	scheduleFrequency?: string
	scheduleIntervalNum?: number
	scheduleIntervalTimeUnit?: string
	scheduleStartTime?: string
	scheduleTimeZone?: string
	scheduleAnchorTime?: number

	// notifications
	notifications?: any[]

	// other configurations
	disabled?: boolean

	// status
	_status?: string
	nextRunTime?: number // in epoch seconds
	lastRunTime?: number // in epoch seconds

	// last job run information
	lastTestRunBillID?: string // id of the last test run bill
	lastRun: TestRunBill // this is only set on the client site, and not included in the server response

	// metadata
	createdAt?: number // in epoch seconds
	updatedAt?: number // in epoch seconds

	constructor(o?: any) {

		if (typeof o === "object" && o != null) {
			Object.keys(o).forEach((prop) => {
				try {
					const value = o[prop]
					if (prop === "nextRunTime_ms") {
						this.nextRunTime = value / 1000
					} else if (prop === "lastRunTime_ms") {
						this.lastRunTime = value / 1000
					} else {
						this[prop] = value
					}
				} catch (e) {
					// ignore
				}
			})
		}

		// process the blueprint (overrides legacy values)
		if (this.blueprint && this.blueprint.length > 0) {

			const filePaths = []
			const browsers = []
			const resolutions = []
			let environmentDataID = null
			let region = null
			let userAgent = null

			const generator = this.blueprint[0]
			if (generator.default) {
				if (generator.default.filePath) {
					filePaths.push(generator.default.filePath)
				}
				if (generator.default.browser) {
					browsers.push(generator.default.browser)
				}
				if (generator.default.resolution) {
					resolutions.push(generator.default.resolution)
				}
				if (generator.default.environmentDataId) {
					environmentDataID = generator.default.environmentDataId
				}
				if (generator.default.region) {
					region = generator.default.region
				}
				if (generator.default.userAgent) {
					userAgent = generator.default.userAgent
				}
			}

			if (generator.matrix) {
				if (generator.matrix.filePaths) {
					filePaths.push(...generator.matrix.filePaths)
				}
				if (generator.matrix.browsers) {
					browsers.push(...generator.matrix.browsers)
				}
				if (generator.matrix.resolutions) {
					resolutions.push(...generator.matrix.resolutions)
				}
			}

			if (generator.matrixList && generator.matrixList.length > 0) {
				generator.matrixList.forEach((config) => {
					if (config.filePath) {
						filePaths.push(config.filePath)
					}
					if (config.browser) {
						browsers.push(config.browser)
					}
					if (config.resolution) {
						resolutions.push(config.resolution)
					}
				})
			}

			// final processing
			this.filePaths = []
			filePaths.forEach((filePath) => {
				if (!this.filePaths.includes(filePath)) {
					this.filePaths.push(filePath)
				}
			})

			this.browsers = []
			browsers.forEach((browser) => {
				if (!this.browsers.includes(browser)) {
					this.browsers.push(browser)
				}
			})

			this.resolutions = []
			resolutions.forEach((resolution) => {
				if (!this.resolutions.includes(resolution)) {
					this.resolutions.push(resolution)
				}
			})

			this.environmentDataId = environmentDataID
			this.region = region
			this.userAgent = userAgent

		} else if (this._filePath) {
			this.filePaths = [this._filePath]
		}

	}

	get displayName(): string {
		if (this.name) {
			return this.name
		}
		// fallback to file path
		if(this.filePath){
			if(this.filePath.endsWith("test.js")){
				return this.filePath.substring(0, this.filePath.length - (".test.js").length)
			}
			return this.filePath
		}
		return "Untitled Job"
	}

	get filePath() {
		return this.filePaths[0]
	}

	set filePath(value: string) {
		this._filePath = value
	}

	get environmentDataID(): string {
		return this.environmentDataId
	}

	set environmentDataID(value: string) {
		this.environmentDataId = value
	}

	get resolution(): string {
		if (this.resolutions.length > 0) {
			return this.resolutions[0]
		}
		return null
	}

	get status(): string {
		if(this.lastRun){
			return this.lastRun.status
		}
		return this._status
	}

	set status(value: string){
		this._status = value
	}

	/**
	 * Is this job currently being ran at the moment?
	 */
	get isRunning(): boolean {
		// first, check if last run has "stop" flag
		if(this.lastRun && this.lastRun.stop){
			return false;
		}
		return this.status === "created" || this.status === "pending"
	}

}
