/*
Register FREQUENTLY used components as global components here
*/

import {
	CakeButton,
	CakeToggleField,
	CakeToggleFieldList,
	CakeSwitch,
	CakeBsFieldGroup,
	CakeBsTextField,
	CakeBsTextFieldGroup,
	CakeBsSelectField,
	CakeBsSelectFieldGroup,
	CakeSelectField,
	CakeCircleProgress,
	CakeBsFileField,
	CakeDatePicker,
	CakeTypeAhead,
	CakeDropdownButton,
	Breadcrumb,
	CakeTabPane,
	LoadingOverlay,
	NotificationBar,
	CakeTag,
} from "@uilicious/cake-ui"

import CrispChat from "@/components/chat/CrispChat.vue"

import Vue from "vue"

let installed = false

if (!installed) {

	//--------------------------------------------------
	// Button
	//--------------------------------------------------
	Vue.component("CakeButton", CakeButton)

	//--------------------------------------------------
	// Modal
	//--------------------------------------------------

	//--------------------------------------------------
	// Form
	//--------------------------------------------------
	Vue.component("CakeToggleField", CakeToggleField)
	Vue.component("CakeToggleFieldList", CakeToggleFieldList)
	Vue.component("CakeSwitch", CakeSwitch)

	//--------------------------------------------------
	// Form (Bootstrap)
	//--------------------------------------------------

	Vue.component("CakeBsFieldGroup", CakeBsFieldGroup)
	Vue.component("CakeBsTextField", CakeBsTextField)
	Vue.component("CakeBsTextFieldGroup", CakeBsTextFieldGroup)
	Vue.component("CakeBsSelectField", CakeBsSelectField)
	Vue.component("CakeBsSelectFieldGroup", CakeBsSelectFieldGroup)
	Vue.component("CakeSelectField", CakeSelectField)
	Vue.component("CakeTypeAhead", CakeTypeAhead)
	Vue.component("CakeBsFileField", CakeBsFileField)
	Vue.component("CakeDatePicker", CakeDatePicker)

	//--------------------------------------------------
	// DropdownMenu
	//--------------------------------------------------

	Vue.component("CakeDropdownButton", CakeDropdownButton)

	//--------------------------------------------------
	// Nav
	//--------------------------------------------------
	Vue.component("Breadcrumb", Breadcrumb)

	//--------------------------------------------------
	// Pane
	//--------------------------------------------------
	Vue.component("CakeTabPane", CakeTabPane)

	//--------------------------------------------------
	// Progress
	//--------------------------------------------------

	Vue.component("LoadingOverlay", LoadingOverlay)
	Vue.component("CakeCircleProgress", CakeCircleProgress)

	//--------------------------------------------------
	// Alerts
	//--------------------------------------------------
	Vue.component("NotificationBar", NotificationBar)

	//--------------------------------------------------
	// Others
	//--------------------------------------------------
	Vue.component("CakeTag", CakeTag)

	//--------------------------------------------------
	// Chat
	//--------------------------------------------------
	Vue.component("CrispChat", CrispChat)

	installed = true

}





