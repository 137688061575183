//--------------------------------------------------
// Static dependencies
//--------------------------------------------------
import Vue from 'vue';
import {ActionTree, GetterTree, Module, MutationTree} from "vuex"
import DirectoryNode from "@uil/models/DirectoryNode"
import api from "@uil/api"
import {flattenNodes} from "@/utils/projectFile"

//--------------------------------------------------
// STATE
//--------------------------------------------------
interface DraggedItem {
	type: "file" | "node"
	node?: DirectoryNode;
}

interface WorkspaceState {
	directory: DirectoryNode | null;
	selected: string[];
	draggedItem: DraggedItem | null,
	targetFolder: DirectoryNode | null,
	nodesByPath: Map<string, DirectoryNode>;
}

const state: WorkspaceState = {
	directory:    null, // tree of all files in the project
	selected:     [], // list of selected files
	// used for directory drag and drop operations
	draggedItem:  null,
	targetFolder: null, // the folder that is the drop target
	nodesByPath:  new Map<string, DirectoryNode>(), // map of all nodes by path
}

function resetWorkspace(state: WorkspaceState) {
	state.directory = null
	state.selected = []
	state.draggedItem = null
	state.targetFolder = null
	state.nodesByPath = new Map<string, DirectoryNode>()
}

//--------------------------------------------------
// MUTATIONS
//--------------------------------------------------
const mutations: MutationTree<WorkspaceState> = {
	reset:        resetWorkspace,
	setDirectory: (state, directory: DirectoryNode) => {
		directory.name = "" // force the root dir to have "" name
		directory.expanded = true

		state.directory = new DirectoryNode(directory)

		state.nodesByPath = new Map<string, DirectoryNode>()
		const nodesFlattened = flattenNodes(state.directory)
		for (const node of nodesFlattened) {
			state.nodesByPath.set(node.path, node)
		}
	},
	selectNode: (state, nodePath: string) => {
		state.selected.splice(0, 1, nodePath)
	},
	toggleNode: (state, node: DirectoryNode) => {
		node.expanded = !node.expanded
	},
	updateNode: (state, payload: { path: string, data: Partial<DirectoryNode> }) => {
		const {path, data} = payload
		const node = state.nodesByPath.get(path)

		if (node) {
			const oldPath = node.path
			Object.assign(node, data)

			if (oldPath !== node.path) {
				state.nodesByPath.delete(oldPath)
				state.nodesByPath.set(node.path, node)
			}
		}
	},
	updateNodeMetadata: (state, payload) => {
		const {path, metadata} = payload
		const node = state.nodesByPath.get(path)
		if(node){
			Vue.set(node, "metadata", metadata);
		}
	},
	setDraggedItem: (state, item: DraggedItem) => {
		state.draggedItem = item
	},
	setTargetFolder: (state, targetFolder: DirectoryNode) => {
		state.targetFolder = targetFolder
	},
	addNode: (state, payload: { parent: DirectoryNode, node: DirectoryNode }) => {
		const {parent, node} = payload

		parent.children.push(node)
		state.nodesByPath.set(node.path, node)
	},
}

//--------------------------------------------------
// GETTERS
//--------------------------------------------------
const getters: GetterTree<WorkspaceState, any> = {
	/**
	 * Get the active project
	 */
	activeProject: (state, getters, rootState) => {
		const projectId = rootState.route.params.projectId
		if (projectId == null) {
			return null
		}
		const project = rootState.projects.map[projectId]
		if (project == null) {
			return null
		}
		return project
	},
	activeSpace: (state, getters, rootState) => {
		const project = getters.activeProject

		if (project == null) {
			return null
		}

		return rootState.spaces.map[project.spaceID]
	},
	billingUrl: (state, getters) => {
		return `${__BILLING_URL__}/space?id=${getters.activeProject?.spaceID}`
	},
	/**
	 * Get the folders in the active project
	 */
	folders:      ({nodesByPath}) => Array.from(nodesByPath.values()).filter(node => node.isFolder),
	/**
	 * Get the test files in the active project
	 */
	tests:        ({nodesByPath}) => Array.from(nodesByPath.values()).filter(node => node.isTest),
	/**
	 * Get the selected file or folder
	 */
	selectedNode: ({nodesByPath, selected}) => {
		if (selected[0]) {
			return nodesByPath.get(selected[0])
		}
		return getters["workspace/activeFile"] // fallback to active file detected based on the url
	},
	/**
	 * Get the currently opened file, based on the url
	 */
	activeFile: ({nodesByPath}, getters, rootState) => {
		const {filePath} = rootState.route.params

		if (filePath != null && filePath !== "") {
			return nodesByPath.get(filePath)
		}
	},
	readmeFile: ({nodesByPath}) => Array.from(nodesByPath.values())
		.find(
			node => node.isFile && node.path.toLowerCase() === "readme.md",
		),
	/**
	 * Return the list of files that are uploading
	 */
	pendingFiles: ({nodesByPath}) => Array.from(nodesByPath.values()).filter(node => node.isUploading),
	/**
	 * Subscription
	 */
	subscription: (state, getters) => getters.activeProject?._space?._subscription ?? undefined,
}

//--------------------------------------------------
// ACTIONS
//--------------------------------------------------
const actions: ActionTree<WorkspaceState, any> = {
	fetchDirectory({commit, state, rootState}): Promise<void> {
		return new Promise((resolve, reject) => {

			// get the project id from route
			const projectId = rootState.route.params.projectId

			// no project selected
			if (projectId == null || projectId === "") {
				const error = new Error("No active project selected.")
				// error.code = "PROJECT_NOT_SELECTED"
				return reject(error)
			}

			// set directory pane state to fetching...

			// fetch project directory
			api.project.file
				.query({
					projectID: projectId,
					type:      "tree",
				})
				.then((data) => {

					// invalid response
					if (data.result == null) {
						throw new Error("missing `result` from response")
					}

					// is the active project still the same
					const activeProjectId = rootState.route.params.projectId
					if (projectId !== activeProjectId) {
						const error = new Error("Active project has been changed.")
						// error.code = "PROJECT_CHANGED"
						return reject(error)
					}

					const expandedNodePaths = Array.from(state.nodesByPath.values())
						.filter(node => node.expanded)
						.map(node => node.path)

					// update the directory
					const rootDirectory = data.result
					commit("setDirectory", rootDirectory)

					// Restore the expanded state of the directory
					for (const path of expandedNodePaths) {
						const node = state.nodesByPath.get(path)

						if (node) {
							node.expanded = true
							node.expandAncestors()
						}
					}

					return resolve()
				})
				.catch(reject)

		})
	},
	protectFile({commit, state}, {projectID, filePath, protect}): Promise<void> {
		let promise;
		if(protect){
			promise = api.project.file.protect({projectID, filePath})
		} else {
			promise = api.project.file.unprotect({projectID, filePath})
		}
		promise.then((data)=>{
			return api.project.file.get({projectID, filePath, rawContent: false})
			.then((data)=>{
				console.log("file data: ", data)
				let metadata = data.metadata
				// update metadata on file
				commit("updateNodeMetadata", {
					path: filePath,
					metadata: metadata
				})
			})
		})
		return promise;
	}
}

//--------------------------------------------------
// EXPORT
//--------------------------------------------------
const WorkspaceStore: Module<WorkspaceState, any> = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}

export default WorkspaceStore
