export const STATUS = {
	QUEUED:               "queued", 		// test request only - request is queued
	CREATED:              "created", 		// test created, but not yet dispatched to test runner
	INIT:                 "init", 			// test created and dispatched to test runner
	PENDING:              "pending", 		// test running
	SUCCESS:              "success", 		// test passed
	FAILURE:              "failure", 		// test failed
	ERROR:                "error", 			// script error
	MAX_REQUEST_ERROR:    "request_max", 	// concurrency limit
	NO_SERVERS_AVAILABLE: "no_servers", 	// uilicious grid is full
	SYSTEM_ERROR:         "system_error", 	// system error
	TERMINATED:           "terminated", 		// terminated by user or by system
} as const

export type TestRunStatusMap = typeof STATUS
export type TestRunStatus = TestRunStatusMap[keyof TestRunStatusMap]