//--------------------------------------------------
// STATE
//--------------------------------------------------
import Vue from "vue"
import {ActionTree, GetterTree, Module, MutationTree} from "vuex"
import {tours} from "@/tours"
import api from "@uil/api"
import cloneDeep from "lodash/cloneDeep"
import {AccountSettings, AccountProjectSettings,  AccountSettingsState, RootState} from "@/store/types"

export type TourNames = keyof typeof tours;

const INITIAL_STATE = {
	// editor settings
	autosave:       true,
	lineWrapping:   false,
	// onboarding tour
	completedTours: Object.keys(tours).reduce((map, tourName) => ({
		...map,
		[tourName]: false,
	}), {} as Record<TourNames, boolean>),
	// project settings
	defaultRegion:                       "", // legacy - it is now preferred to set this on projectSettings map
	projectSettings: {},
	// other settings
	aiAssistDataProtectionTermsAgreedAt: undefined,
}

const state: AccountSettingsState = INITIAL_STATE

export enum Mutation {
	Reset = "reset",
	Set = "set",
	MarkTourAsCompleted = "markTourAsCompleted",
	UpdateProjectSettings = "updateProjectSettings"
}

const mutations: MutationTree<AccountSettingsState> = {
	[Mutation.Reset] (state) {
		Object.assign(state, cloneDeep(INITIAL_STATE))
	},
	[Mutation.Set] (state, settings: Partial<AccountSettings>) {
		Object.keys(settings).forEach((key)=>{
			let oldValue = state[key]
			let newValue = settings[key]
			if(typeof state[key] === "boolean"){
				newValue = Boolean(newValue)
			}
			if(oldValue !== newValue){
				Vue.set(state, key, newValue)
			}
		})
	},
	[Mutation.MarkTourAsCompleted] (state, tourName: TourNames) {
		if(Object.keys(tours).includes(tourName)){
			Vue.set(state.completedTours, tourName, true)
		}
	},
	[Mutation.UpdateProjectSettings] (state, payload: AccountProjectSettings){

		let projectID = payload.projectID

		// create settings object for this project
		if(!state.projectSettings[projectID]){
			Vue.set(state.projectSettings, projectID, {
				defaultDataset: "",
				defaultRegion: "",
				defaultTestRunConfiguration: ""
			})
		}

		// update settings for this project
		Object.keys(state.projectSettings[projectID]).forEach((key)=>{
			let oldValue = state.projectSettings[projectID][key]
			let newValue = payload[key]
			if(typeof newValue !== "undefined" && newValue !== oldValue){
				Vue.set(state.projectSettings[projectID], key, newValue)
			}
		})

	}
}

const getters: GetterTree<AccountSettingsState, RootState> = {
	defaultDatasetForCurrentProject(state, getters, rootState){
		const projectID = rootState.route.params.projectId
		if(state.projectSettings[projectID]){
			let datasetID = state.projectSettings[projectID].defaultDataset
			if(datasetID){
				return rootState.datasets.map[datasetID]
			}
		}
		return null
	},
	defaultRegionForCurrentProject(state, getters, rootState){
		const projectID = rootState.route.params.projectId
		if(state.projectSettings[projectID]?.defaultRegion){
			return state.projectSettings[projectID].defaultRegion
		}
		if(state.defaultRegion){
			return state.defaultRegion
		}
		return ""
	},
	defaultTestRunConfigurationForCurrentProject(state, getters, rootState){
		const projectID = rootState.route.params.projectId
		let configurationID = state.projectSettings[projectID]?.defaultTestRunConfiguration
		if(configurationID){
			return rootState.testRunConfigurations.map[configurationID]
		}
		return null
	}
}

const actions: ActionTree<AccountSettingsState, RootState> = {
	/**
	 * Load account settings from database and sync to store
	 * @param state
	 * @param commit
	 */
	async load ({state, commit}) {

		const {result} = await api.account.settings.get()

		Object.keys(result).forEach(key => {

			let value = result[key]

			// convert from ['tour1', 'tour2'] to {'tour1': true, 'tour2' true}
			if(key === "completedTours"){
				let completedTours: string[] = JSON.parse(value)
				completedTours.forEach(tourName => {
					commit(Mutation.MarkTourAsCompleted, tourName)
				})
				return;
			}

			// project settings
			if(key.startsWith("/project")){
				let tokens = key.split("/")
				let projectID = tokens[2].trim()
				let settingName = tokens[3].trim()
				commit(Mutation.UpdateProjectSettings, {
					projectID,
					[settingName]: value
				})
				return
			}

			// simple update
			commit(Mutation.Set, {[key] : value})

		})

		console.log("User's settings loaded")

	},
	/**
	 * Update account settings in store, and save to database
	 * @param commit
	 * @param dispatch
	 * @param payload
	 */
	update ({commit, dispatch}, payload: AccountSettings) {

		// save to store
		commit(Mutation.Set, payload)

		// save to database
		let params:any = {}
		Object.keys(payload).forEach((key)=>{
			let value = payload[key]
			if(typeof value === "boolean"){
				params[key] = value ? 1 : 0
			}else{
				params[key] = value
			}
		})

		return api.account.settings.set(params)
				.catch((error)=>{
					console.error("Error saving user settings to server: ", error)
				})

	},
	/**
	 * Update account project settings in store, and save to database
	 * @param commit
	 * @param dispatch
	 * @param payload
	 */
	updateProjectSettings({state, commit, dispatch}, payload: AccountProjectSettings){

		// save to store
		commit(Mutation.UpdateProjectSettings, payload)

		// save to database
		let params:any = {}
		let projectID = payload.projectID
		Object.keys(payload).forEach((key)=>{
			// skip keys
			if(key === "projectID"){
				return true;
			}
			// values
			let value = payload[key]
			if(typeof value === "boolean"){
				value = value ? 1 : 0
			}
			params[`/project/${projectID}/${key}`] = value
		})

		// clean up: if setting defaultRegion on project, reset defaultRegion set on settings root level
		if(typeof payload.defaultRegion !== undefined /* default region is set on this project */ && state.defaultRegion !== ""){
			commit(Mutation.Set, { defaultRegion: "" })
			params.defaultRegion = ""
		}

		return api.account.settings.set(params)
				.catch((error)=>{
					console.error("Error saving user settings to server: ", error)
				})

	},
	/**
	 * Mark tour as completed in store, and save to database
	 * @param commit
	 * @param dispatch
	 * @param tourName
	 */
	markTourAsCompleted ({state, commit, dispatch}, tourName: TourNames) {

		// save to store
		commit(Mutation.MarkTourAsCompleted, tourName)

		// save to database
		let params = {
			completedTours: Object.keys(state.completedTours)
		}

		return api.account.settings.set(params)
				.catch((error)=>{
					console.error("Error saving user settings to server: ", error)
				})

	},
}

const AccountSettingsState: Module<AccountSettings, RootState> = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}

export default AccountSettingsState
