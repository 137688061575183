import Vue from "vue"
import VuePermissions from "@/plugins/vue-permissions"

export const PERMISSIONS = {
	SPACE: {
		VIEW:    "SPACE/VIEW",
		MANAGE:  "SPACE/MANAGE",
		PROJECT: {
			VIEW:   "SPACE/PROJECT/VIEW",
			CREATE: "SPACE/PROJECT/CREATE"
		},
		MEMBER: {
			VIEW:   "SPACE/MEMBER/VIEW",
			MANAGE: "SPACE/MEMBER/MANAGE"
		},
		INVITATION: {
			VIEW:   "SPACE/INVITATION/VIEW",
			MANAGE: "SPACE/INVITATION/MANAGE"
		}
	},
	PROJECT: {
		VIEW:   "PROJECT/VIEW",
		MANAGE: "PROJECT/MANAGE", // rename and delete
		FILE:   {
			VIEW: "PROJECT/FILE/VIEW",
			EDIT: "PROJECT/FILE/EDIT",
			// edit permissions on a file
			EDIT_PERMISSIONS: "PROJECT/FILE/EDIT_PERMISSIONS",
		},
		TEST: {
			RUN: "PROJECT/TEST/RUN"
		},
		JOB: {
			VIEW:   "PROJECT/JOB/VIEW",
			MANAGE: "PROJECT/JOB/MANAGE", // create, edit, and delete
			RUN:    "PROJECT/JOB/RUN"
		},
		REPORT: {
			VIEW: "PROJECT/REPORT/VIEW"
		},
		RUNS: {
			VIEW: "PROJECT/RUNS/VIEW"
		}
	}
}

/* TODO: this should be gotten from the api */
export const SPACE_PERMISSIONS_FOR_ROLE = {
	owner: [
		PERMISSIONS.SPACE.VIEW,
		PERMISSIONS.SPACE.MANAGE,
		PERMISSIONS.SPACE.PROJECT.VIEW,
		PERMISSIONS.SPACE.PROJECT.CREATE,
		PERMISSIONS.SPACE.MEMBER.VIEW,
		PERMISSIONS.SPACE.MEMBER.MANAGE,
		PERMISSIONS.SPACE.INVITATION.VIEW,
		PERMISSIONS.SPACE.INVITATION.MANAGE
	],
	admin: [
		PERMISSIONS.SPACE.VIEW,
		PERMISSIONS.SPACE.MANAGE,
		PERMISSIONS.SPACE.PROJECT.VIEW,
		PERMISSIONS.SPACE.PROJECT.CREATE,
		PERMISSIONS.SPACE.MEMBER.VIEW,
		PERMISSIONS.SPACE.MEMBER.MANAGE,
		PERMISSIONS.SPACE.INVITATION.VIEW,
		PERMISSIONS.SPACE.INVITATION.MANAGE
	],
	editor: [
		PERMISSIONS.SPACE.VIEW,
		// PERMISSIONS.SPACE.MANAGE,
		PERMISSIONS.SPACE.PROJECT.VIEW,
		// PERMISSIONS.SPACE.PROJECT.CREATE,
		PERMISSIONS.SPACE.MEMBER.VIEW
		// PERMISSIONS.SPACE.MEMBER.MANAGE,
		// PERMISSIONS.SPACE.INVITATION.VIEW,
		// PERMISSIONS.SPACE.INVITATION.MANAGE
	],
	reporter: [
		PERMISSIONS.SPACE.VIEW,
		// PERMISSIONS.SPACE.MANAGE,
		PERMISSIONS.SPACE.PROJECT.VIEW,
		// PERMISSIONS.SPACE.PROJECT.CREATE,
		PERMISSIONS.SPACE.MEMBER.VIEW
		// PERMISSIONS.SPACE.MEMBER.MANAGE,
		// PERMISSIONS.SPACE.INVITATION.VIEW,
		// PERMISSIONS.SPACE.INVITATION.MANAGE
	]
}

export const PROJECT_PERMISSIONS_FOR_ROLE = {
	owner: [
		PERMISSIONS.PROJECT.VIEW,
		PERMISSIONS.PROJECT.MANAGE,
		PERMISSIONS.PROJECT.FILE.VIEW,
		PERMISSIONS.PROJECT.FILE.EDIT,
		PERMISSIONS.PROJECT.FILE.EDIT_PERMISSIONS,
		PERMISSIONS.PROJECT.TEST.RUN,
		PERMISSIONS.PROJECT.JOB.VIEW,
		PERMISSIONS.PROJECT.JOB.MANAGE,
		PERMISSIONS.PROJECT.JOB.RUN,
		PERMISSIONS.PROJECT.REPORT.VIEW
	],
	admin: [
		PERMISSIONS.PROJECT.VIEW,
		PERMISSIONS.PROJECT.MANAGE,
		PERMISSIONS.PROJECT.FILE.VIEW,
		PERMISSIONS.PROJECT.FILE.EDIT,
		PERMISSIONS.PROJECT.FILE.EDIT_PERMISSIONS,
		PERMISSIONS.PROJECT.TEST.RUN,
		PERMISSIONS.PROJECT.JOB.VIEW,
		PERMISSIONS.PROJECT.JOB.MANAGE,
		PERMISSIONS.PROJECT.JOB.RUN,
		PERMISSIONS.PROJECT.REPORT.VIEW
	],
	editor: [
		PERMISSIONS.PROJECT.VIEW,
		// PERMISSIONS.PROJECT.MANAGE,
		PERMISSIONS.PROJECT.FILE.VIEW,
		PERMISSIONS.PROJECT.FILE.EDIT,
		PERMISSIONS.PROJECT.TEST.RUN,
		PERMISSIONS.PROJECT.JOB.VIEW,
		PERMISSIONS.PROJECT.JOB.MANAGE,
		PERMISSIONS.PROJECT.JOB.RUN,
		PERMISSIONS.PROJECT.REPORT.VIEW
	],
	reporter: [
		PERMISSIONS.PROJECT.VIEW,
		// PERMISSIONS.PROJECT.MANAGE,
		PERMISSIONS.PROJECT.FILE.VIEW,
		// PERMISSIONS.PROJECT.FILE.EDIT,
		// PERMISSIONS.PROJECT.TEST.RUN,
		PERMISSIONS.PROJECT.JOB.VIEW,
		// PERMISSIONS.PROJECT.JOB.MANAGE,
		// PERMISSIONS.PROJECT.JOB.RUN,
		PERMISSIONS.PROJECT.REPORT.VIEW
	]
}

Vue.mixin({
	created(){
		// expose PERMISSIONS constant to all components
		this.PERMISSIONS = PERMISSIONS
	}
})

Vue.use(VuePermissions)

export const permissions = new VuePermissions()

export default permissions

