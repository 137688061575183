var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", {
    attrs: {
      title: "Upgrade plan",
      "confirm-btn-text": "Close",
      "show-close-btn": false,
      size: "md",
    },
    on: { confirm: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("p", [
              _vm._v(
                "Looks like you don't have permission to change the subscription plan for this workspace."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("Contact the owner of your space to upgrade your plan."),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }